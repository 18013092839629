// src/stores/counter.js
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  // 定义 state，类似于组件中的 data
  state: () => ({
    downloadId:'1864658970870943744',
    publicVapidKey : 'BGN09H3smUuxAejdJAupJTocvWert9ZcPeAB8n2thDMO0C_BfaBaUWsjpAKn58r0Z-w52lgNseN0sTT9PQPZSGs',
    downloadUrl : '/prod-api',
    type:'facebook',
    pixelCode:'1201674067761049',
    model:'模板六',
    // appurl:'https://wuwad.xyz',
    appurl:'https://wuwad.xyz',
    urltype:'1',
  }),
});
