import { createRouter, createWebHistory } from 'vue-router';
import Page from '../views/pwa.vue'; // 示例组件
import Home from '../views/index.vue'
import IOS from '../views/Ios.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ios',
    name: 'IOS',
    component: IOS,
  },
  {
    path: '/pwa',
    name: 'page',
    component: Page,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
