<template>
  <div style="width: 100%;height: 100%;">
    <!-- Your component HTML goes here -->
     <iframe  :src="counter.appurl" frameborder="0" id="myIframe" style="width: 100vw;height: 100vh;" allow="fullscreen"></iframe>
  </div>
</template>

<script>
// Composition API setup
import { onMounted,nextTick } from 'vue';
import { useCounterStore } from '@/stores/index';
export default {
  // name: 'Home',
  setup() {
    const counter = useCounterStore();
    // const ss=()=>{
    //   const iframe = document.getElementById("myIframe");
    //     console.log(iframe);
    //     // if (iframe.requestFullscreen) {
    //     //   console.log('anyweao');
    //     //     iframe.requestFullscreen();
    //     // } else if (iframe.mozRequestFullScreen) { /* Firefox */
    //     //   console.log('Firefox');
    //     //     iframe.mozRequestFullScreen();
    //     // } else if (iframe.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    //     //   console.log('Chrome');
          
    //     //     iframe.webkitRequestFullscreen();
    //     // } else if (iframe.msRequestFullscreen) { /* IE/Edge */
    //     //   console.log('IE/Edge');
    //     //     iframe.msRequestFullscreen();
    //     // }
    // }
    onMounted(() => {  
      let ISios=navigator.userAgent || navigator.vendor || window.opera;
      if(/iPad|iPhone|iPod/.test(ISios) && !window.MSStream) {
     
        // document.addEventListener("DOMContentLoaded",fullscreen(iframe))
        
       }else {
        // window.location.href = counter.appurl; 
       }
     });  



    return {
      counter,
    }

  }
}

</script>
<style scoped>
iframe {
  margin: 0;
  padding: 0;
    width: 100%;
    height: 100%;
    border: none; /* Optional: Remove the border around the iframe */
    overflow-clip-margin: content-box !important;
    overflow: clip !important;
    overflow: hidden; /* Hide the scrollbars from the iframe */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
iframe::-webkit-scrollbar {
    display: none;
    width: 0; /* 适用于水平滚动条 */
    height: 0; /* 适用于垂直滚动条 */
}
</style>