<template>
    <div>
      <!-- 头部 -->
      <div style="width: 100%;min-height: 200px;overflow: hidden;">
		<video v-if="appData.bannertype==2" style="width: 100%;" autoplay muted :src=appData.topbanner loop></video>
		<img v-else style="width: 100%;height: 100%;" :src=appData.topbanner alt="">
	  </div>
      <!-- 中间部分 -->
      <main>
        <section>
          <!-- app信息模块 -->
          <div class="container">
            <!-- 公司信息 -->
            <div class="information">
              <div class="appicon_left">
                <img class="appiocns" :src="appData.applicationIcon" alt=""/>
                <div class="softwarename">
					<div class="apptitlebox">
						<h1 class="appnanme" >{{ appData.application }}</h1>
						<span class="companyinfo">{{ appData.company }}</span>
					</div>
                  
				  <!-- <div class="app-actions"> -->
                  <button class="install-btn" id="install-btn" @click="installhandle()">IOS Clip</button>
				  <button class="install-btn" id="play-btn" style="display: none" @click="goplay">play</button>
                <!-- </div> -->
                </div>
              </div>
              <div class="appnameinfo">
                <div class="app-rating-download">
                  <div class="rating">
					<span>Views</span>
                    <span>{{ appData.reviews }}</span>
					<span style="color: #ccc;height: 20px;">★★★★★</span>
                  </div>
				  <div class="rating">
					<span>Age</span>
                    <span>14+</span>
					<span style="color: #ccc;">old</span>
                  </div>
				  <div class="rating">
					<span>rankings</span>
                    <span>#2</span>
					<span  style="color: #ccc;display: block;width: 18px;"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20"><g fill="none"><path d="M8.5 4.498a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0zm1.5-2.5a2.5 2.5 0 0 0-2.43 3.086L5.471 4.15a1.761 1.761 0 0 0-2.317.88c-.4.882-.008 1.917.877 2.31L7 8.662v2.287l-1.877 4.645a1.75 1.75 0 0 0 3.245 1.311l.086-.213a5.465 5.465 0 0 1-.424-1.618l-.589 1.457a.75.75 0 1 1-1.39-.562l1.876-4.646A1 1 0 0 0 8 10.95V8.662a1 1 0 0 0-.593-.914L4.438 6.427a.739.739 0 0 1-.373-.983a.761.761 0 0 1 1-.38l3.918 1.744a2.5 2.5 0 0 0 2.034 0l3.918-1.744a.761.761 0 0 1 1 .38a.739.739 0 0 1-.373.983l-2.969 1.321a1 1 0 0 0-.593.914v.545a5.48 5.48 0 0 1 1-.185v-.36l2.968-1.322a1.74 1.74 0 0 0 .877-2.31a1.761 1.761 0 0 0-2.317-.88l-2.097.934a2.5 2.5 0 0 0-2.43-3.086zM18 14.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0zm-2.146-1.854a.5.5 0 0 0-.708 0L12.5 15.293l-.646-.647a.5.5 0 0 0-.708.708l1 1a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0 0-.708z" fill="currentColor"></path></g></svg></span>
                  </div>
                  <div class="rating">
					<span>Downloads</span>
                    <span>{{ appData.downloadNumber }}</span>
					<span  style="color: #ccc;display: block;width: 18px;"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M472.7 189.5c-15.76-10-36.21-16.79-58.59-19.54c-6.65-39.1-24.22-72.52-51.27-97.26C334.15 46.45 296.21 32 256 32c-35.35 0-68 11.08-94.37 32a149.7 149.7 0 0 0-45.29 60.42c-30.67 4.32-57 14.61-76.71 30C13.7 174.83 0 203.56 0 237.6C0 305 55.92 352 136 352h104V208h32v144h124c72.64 0 116-34.24 116-91.6c0-30.05-13.59-54.57-39.3-70.9z" fill="currentColor"></path><path d="M240 419.42L191.98 371l-22.61 23L256 480l86.63-86l-22.61-23L272 419.42V352h-32v67.42z" fill="currentColor"></path></svg></span>
                  </div>
                  <div class="age-rating">
					<span>Reviews</span>
                    <span>{{ appData.reviews }}</span>
					<span style="color: #ccc;display: block;width: 18px;"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><circle cx="256" cy="256" r="64" fill="currentColor"></circle><path d="M394.82 141.18C351.1 111.2 304.31 96 255.76 96c-43.69 0-86.28 13-126.59 38.48C88.52 160.23 48.67 207 16 256c26.42 44 62.56 89.24 100.2 115.18C159.38 400.92 206.33 416 255.76 416c49 0 95.85-15.07 139.3-44.79C433.31 345 469.71 299.82 496 256c-26.38-43.43-62.9-88.56-101.18-114.82zM256 352a96 96 0 1 1 96-96a96.11 96.11 0 0 1-96 96z" fill="currentColor"></path></svg></span>
                  </div>
                </div>
                <!-- <div class="app-actions">
                  <button class="install-btn" @click="installhandle()">IOS Clip</button>
                </div> -->
              </div>
            </div>
            <!-- app图标 -->
            <div class="appicon">
              <img :src="appData.applicationIcon" alt=""/>
            </div>
          </div>
          <!-- 中间部分模块 -->
          <div class="appcontent">
            <!-- 大屏左边部分 -->
            <section class="content_left">
              <!-- 轮播图模块 -->
              <div class="carousel-container">
                <!-- <n-carousel :slides-per-view="bannernumber" :space-between="10" :loop="false" draggable>
                   <img class="carousel-images" :src="item" alt="" v-for="item in appData.detailImages" :key="item" >
                </n-carousel> -->
				<n-carousel  :space-between="10" :show-dots="false" :loop="false" draggable>
                   <img class="carousel-images" :src="item" alt="" v-for="item in appData.detailImages" :key="item" >
                </n-carousel>
              </div>
              
              <!-- 关于此应用 -->
              <div class="aboutappliction">
                <section class="aboutapp">
                  <h2 class="avoutthisapp">About this application</h2>
                  <span class="iconfont icon-youjiantou"></span>
                </section>
                <section class="appdetail">
                  <p class="fontcontent">
                    {{ appData.introduction }}
                  </p>
  
                </section>
                <section class="updatetime">
                  <p>updated on：</p>
                  <p>{{ appData.updateTime }}</p>
                </section>
  
              </div>
  
              <!-- 评分和评论 -->
              <div class="Ratingreviews">
                <section class="aboutapp">
                  <h2 class="avoutthisapp">Ratings and Reviews</h2>
                  <span class="iconfont icon-youjiantou"></span>
                </section>
                <div class="rating-section">
                  <!-- 评分进度条 -->
                  <div class="rating-summary">
                    <div class="rating-score">
                      <div class="score">{{ appData.score }}</div>
                      <div class="stars">★★★★★</div>
                      <div class="reviews"></div>
                    </div>
                    <div class="rating-distribution">
                      <div class="rating-bar">
                        <span class="label">5</span>
                        <div class="bar">
                          <div class="fill" style="width: 80%"></div>
                        </div>
                      </div>
                      <div class="rating-bar">
                        <span class="label">4</span>
                        <div class="bar">
                          <div class="fill" style="width: 60%"></div>
                        </div>
                      </div>
                      <div class="rating-bar">
                        <span class="label">3</span>
                        <div class="bar">
                          <div class="fill" style="width: 40%"></div>
                        </div>
                      </div>
                      <div class="rating-bar">
                        <span class="label">2</span>
                        <div class="bar">
                          <div class="fill" style="width: 20%"></div>
                        </div>
                      </div>
                      <div class="rating-bar">
                        <span class="label">1</span>
                        <div class="bar">
                          <div class="fill" style="width: 30%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 评论内容 1-->
                  <div v-if="appData.reviewFormList.length != 0">
                    <div v-for="item in appData.reviewFormList" :key="item.id" class="review">
                      <div class="review-header">
                        <div class="bgpic">
                          <img :src="item.icon" :alt="item.name" class="pic">
                          <span class="review-author">{{ item.name }}</span>
                        </div>
                        <span class="more">more</span>
                      </div>
  
                      <div class="commentdata">
                        <!-- <span class="review-rating">★★★★☆</span> -->
                        <span class="review-date">{{ item.createTime }}</span>
                      </div>
                      <div class="review-content">
                        <p class="fontcontent">
                          {{ item.review }}
                        </p>
                      </div>
                      <div class="review-footer">
                        <span class="helpful-count">26,542 people found this review useful</span>
                      </div>
                    </div>
  
                  </div>
                  <div class="viewallreview">
                    <span class="viewallcomment">see all comment</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <!-- 尾部 -->
        <footer>
          <div class="privacy">
            <p class="termsofservice">
              <a href=""><span>Terms of Service</span></a>
              <a href=""><span>privacy</span></a>
              <a href="#about"><span>About App</span></a>
            </p>
          </div>
        </footer>
      </main>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, computed,onUnmounted } from 'vue'
  import { useCounterStore } from '@/stores/index';
  export default {
    // name: 'Home',
    setup() {
      // 使用 ref 创建响应式数据  
      
      const counter = useCounterStore();
      // const downloadUrl = ref('https://test.nikozhou.com:82/prod-api'); // 下载地址（服务器IP地址）  
      // const downloadId = ref('1837097112395976704'); // 参数ID  
      const appData = ref({
        application: "", // APP名称  
        language: 0,
        applicationIcon: "", // APP图标  
        company: "", // 公司名称  
        viewNumber: "",
        score: "4.5",
        downloadNumber: "", // 下载数量  
        reviews: "", // 评论数  
        updateTime: "",
        detailImages: [], // app详情图片  
        introduction: "", // APP介绍  
        reviewFormList: [
          {
            icon: "", // 用户头像  
            name: "", // 用户名  
            createTime: "",
            review: "" // 用户评论  
          }
        ]
      });
      // let showpalybtn=ref(true)
      // let deferredPrompt = null;
  // 安装
  const installhandle=()=>{
    window.location.href='/ios'
  }
      // 请求最新数据  
      const getdatalist = async () => {
        try {
          const response = await fetch(`${counter.downloadUrl}/application/ad/${counter.downloadId}`, { method: 'GET' });
          const data = await response.json();
          console.log(data);
          if (data.code === 200) {
            appData.value = data.data; // 合并新数据到appData中  
          }
        } catch (error) {
          console.log('Error:', error);
          // populateData(); // 如果populateData是用来显示默认数据或错误消息的，那么在这里调用它  
        }
      };
  // 轮播图
  // 创建一个响应式引用，用于存储屏幕宽度  
  let bannernumber = ref(5);  
  // 定义一个函数，用于更新屏幕宽度  
  const updateScreenWidth = () => {  
    console.log(window.innerWidth);
    
    if(window.innerWidth<600){
      bannernumber.value = 3; 
    }else{
      bannernumber.value = 5; 
    }
  }; 
  
   
      // 在组件挂载后执行  
      onMounted(() => {
      
        getdatalist();
        updateScreenWidth()
        window.addEventListener('resize', updateScreenWidth); 
      });

  
     
      // 返回响应式数据和方法  
      return {
        appData,
        // 轮播图 
        bannernumber,
        installhandle
      };
    },
  };
  </script>
  <style lang="css" scoped>
  /* 全局样式 */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}
html {
    scroll-behavior: smooth;
}
body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background-color: white;
}



	main {
		display: flex;
		/* height: calc(100vh - 64px); */
		/* 高度为视口高度减去64px */
		/* padding-top: 64px; */
		/* 上边距为64px */
		box-sizing: border-box;
		flex-direction: column;
		max-width: 1200px;
		/* 版心最大宽度，可以根据需求调整 */
		margin: 0 auto;
		/* 水平居中 */
		padding: 10px 20px;
		/* 添加内边距，避免内容紧贴页面边缘 */
		/* margin-top: 80px; */
	}
	main .container {
		text-align: center;
		/* 文本居中 */
		/* max-width: 90%; 控制内容的最大宽度 */
		width: 100%;
		/* 宽度100% */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.container a {
			text-decoration: none;
			color: #00A173;
	}
	.container .information,
	.softwarename,
	.appnameinfo {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	
	.softwarename{
		justify-content: space-between;
		padding: 5px 0;
	}
	.apptitlebox{
		width: 100%;
		display: flex;
		flex-direction:column;
		padding-bottom: 15px;
	}
	.appnanme{
		text-align: left;
	}
	.companyinfo{
		text-align: left;
		font-size: 12px;
		color: #ccc;
	}
	.appdetail,
	.updatetime {
		color: #5f6368;
		font-size: 0.875rem;
	}
	
	.fontcontent {
		line-height: 1.25rem;
		word-break: break-all;
		text-align: left;
	}
	
	.updatetime {
		display: flex;
		align-items: center;
		margin-top: 24px;
	}
	.container .information {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		width: 100%;
	}
	.appnameinfo{
		position: relative;
		width: 100%;
		overflow: auto;

	}
	.appnameinfo::-webkit-scrollbar{
		width: 0;
		height: 0;
	}
	.app-rating-download {
		display: flex;
		justify-content: space-around;
		margin: 20px 0;
	}
	

	.rating,
	.age-rating {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 20px;
		border-right: 1px solid #e4e1e1;
		text-align: center;
		font-size: 14px;
		color: #333;
	}
	.age-rating{
		border-right: none;
	}
	.icon_with_text .icon-text {
		display: none;
		/* 隐藏文字 */
	}
	.app-actions {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}
.install-btn {
		font-size: 16px;
		background-color: #01875f;
		color: white;
		border: none;
		border-radius: 21px;
		height: 48px;
		padding: 8px;
		/* margin-right: 15px; */
		min-width: 200px;
		/* 设置最小宽度为 200px */
		/* width: auto; */
	}
	.app-actions .secondary-actions {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	.secondary-actions span {
		font-size: 16px;
		color: #01875f;
		text-decoration: none;
		display: flex;
		align-items: center;
	}
	
	.secondary-actions .icon-fenxiang {
		font-size: 50px;
	}
	
	.secondary-actions .icon-weibiaoti2fuzhi03 {
		font-size: 30px;
		font-weight: 800;
	}
	.appiocns {
		display: none;
		width: 130px;
		height: 130px;
		border-radius: 5px;
		margin-right: 15px;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
	}
	.appcontent {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		margin: 0 auto;
		padding: 20px 0px 0px 0px;
	}
	.content_left {
			border-radius: 8px;
			width: 100%;
            /* height: 420px; */
		}
	.content_right {
			flex-basis: 25%;
			background-color: #fff;
			padding: 30px;
			border-radius: 8px;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		}
	/* 轮播图模块开始 */

	.carousel-container {
		border-bottom: 1px solid #ccc;
		padding-bottom: 24px;
        height: 420px;
	}
	.carousel-images {
		/* display: flex;
		transition: transform 0.5s ease; */
		border-radius: 6px;
        width: 100%;
  height: 100%;
  object-fit: cover;
	}
	
	/* 轮播图结束 */
	/* 关于此应用开始 */
	.aboutappliction{
		margin-top: 15px;
	}
	.aboutappliction,
	.safesecurity,
	.newfunction {
		margin-bottom: 24px;
	}
	
	.aboutappliction .aboutapp,
	.Ratingreviews .aboutapp,
	.newfunction h2 {
		padding-bottom: 20px;
	}
	
	.safesecurity .avoutthisapp,
	.aboutappliction .avoutthisapp,
	.Ratingreviews .avoutthisapp,
	.newfunction h2 {
		font-weight: 400 !important;
		margin-right: 10px;
	}
	
	.newfunction p {
		color: #5f6368;
	}
	.aboutapp {
		display: flex;
		align-items: center;
	}
	
	.aboutapp span {
		font-size: 22px;
		font-weight: 800;
		color: #8d8d8d;
		padding: 13px;
		border-radius: 50%;
		/* 圆形背景 */
		transition: background-color 0.3s ease;
		/* 添加平滑过渡效果 */
	}
	
	.aboutapp span:hover {
		background-color: #ecebeb;
		/* 灰色背景 */
		cursor: pointer;
	}
	
	
	
	.differentlabels {
		display: flex;
		flex-wrap: wrap;
		gap: 16px 12px;
		margin-top: 28px;
	}
	.tag {
		display: inline-block;
		padding: 4px 12px;
		/* 调整标签内的间距 */
		border: 1px solid #d9d9d9;
		/* 边框颜色 */
		border-radius: 20px;
		/* 圆角效果 */
		font-size: 0.875rem;
		/* 字体大小 */
		color: #666666;
		/* 文字颜色 */
		white-space: nowrap;
		/* 防止文字换行 */
		line-height: 1.5;
		/* 行高 */
		text-align: center;
		/* 文字居中 */
	}
	/* 关于此应用结束 */
	/* 数据安全开始 */
	.info-box {
		border: 1px solid #d9d9d9;
		/* 边框颜色 */
		border-radius: 8px;
		/* 圆角效果 */
		padding: 16px;
		/* 内边距 */
		padding-top: 0px;
		background-color: #fff;
		/* 背景颜色 */
		max-width: 600px;
		/* 最大宽度 */
		margin-top: 30px;
		/* 居中显示 */
	}
	
	.info-list {
		list-style: none;
		/* 去除列表项的默认样式 */
		padding: 0;
		margin: 0 0 16px 0;
	}
	.info-list li {
		display: flex;
		/* 使用flex布局 */
		align-items: center;
		/* 垂直居中 */
		margin-bottom: 12px;
		/* 列表项间距 */
		font-size: 0.875rem;
		/* 设置字体大小 */
		color: #666666;
		/* 字体颜色 */
		margin-top: 20px;
	}
	
	
	.info-list li a {
		text-decoration: underline;
		color: #666;
	}
	
	.info-list li:last-child {
		margin-bottom: 0;
		/* 最后一个列表项不需要额外的下间距 */
	}
	
	.info-list .nosharewith {
		display: flex;
		flex-direction: column;
	}
	.iconfont {
		font-size: 1.5rem;
		color: #4a4a4a;
		margin-right: 8px;
	}
	
	.details-link {
		font-size: 0.875rem;
		color: #01875f;
		text-decoration: none;
		margin: 10px 0;
	}
	
	.details-link:hover {
		text-decoration: underline;
	}
	
	/* 数据安全结束 */
	/* 评分和评论 开始*/
	.rating-section {
		display: flex;
		flex-direction: column;
		/* max-width: 600px; */
		font-family: Arial, sans-serif;
	}
	
	.device-selector {
		display: flex;
		gap: 8px;
		margin-bottom: 16px;
	}
	.device {
		padding: 8px 16px;
		border: 1px solid #e0e0e0;
		border-radius: 20px;
		background-color: #fff;
		font-size: 0.875rem;
		cursor: pointer;
		transition: background-color 0.3s ease;
	}
	
	.device .active {
		background-color: #e0f2f1;
		border: none;
	}
	
	.device:hover {
		background-color: #f5f5f5;
	}
	
	.rating-summary {
		display: flex;
		gap: 16px;
		align-items: flex-start;
	}
	
	.rating-score {
		text-align: center;
	}
	.score {
		font-size: 3rem;
		font-weight: bold;
		line-height: 1;
	}
	
	.stars {
		color: #01875f;
		font-size: 1.2rem;
	}
	
	.reviews {
		font-size: 0.875rem;
		color: #666;
		margin-top: 8px;
	}
	
	.rating-distribution {
		flex-grow: 1;
	}
	
	.rating-bar {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}
	.rating-bar .label {
		width: 20px;
		font-size: 0.875rem;
		color: #666;
	}
	
	.bar {
		flex-grow: 1;
		height: 10px;
		background-color: #e0e0e0;
		border-radius: 5px;
		overflow: hidden;
		margin-left: 8px;
	}
	
	.bar .fill {
		height: 100%;
		background-color: #01875f;
		border-radius: 5px;
	}
	/* 评分和评论 结束*/
	/* 评论内容开始 */
	.review {
		border-radius: 8px;
		padding: 24px 0;
		background-color: #fff;
	}
	
	.review2 .bgpic .pic {
		background-color: pink;
	}
	
	.review-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.bgpic {
		display: flex;
		align-items: center;
	}
	.pic {
		border-radius: 50%;
		width: 32px;
		height: 32px;
		overflow: hidden;
		background-size: cover;
		background-color: orange;
		font-size: 18px;
		line-height: 32px;
		text-align: center;
		margin-right: 8px;
		color: white;
	}
	
	.review-author {
		font-weight: bold;
	}
	
	.commentdata {
		margin-top: 16px;
        display: flex;
	}
	
	.review-rating {
		color: #01875f;
		/* 星星的颜色 */
	}
	
	.review-date {
		font-size: 12px;
		color: #757575;
		/* 日期的颜色 */
	}
	
	.review-content {
		margin: 12px 0;
		line-height: 1.5;
        display: flex;
		word-break: break-all;
	}
	.review-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	.helpful-count {
		color: #757575;
		/* 有用人数的颜色 */
	}
	
	.helpful-buttons {
		display: flex;
		align-items: center;
		margin-top: 10px;
		gap: 8px;
	}
	
	.helpfulask {
		color: #757575;
	}
	
	button {
		border: 1px solid #efefef;
		background-color: #fff;
		color: #333;
		padding: 5px 20px;
		border-radius: 17px;
		cursor: pointer;
		transition: background-color 0.3s;
	}
	
	button:hover {
		/* background-color: #f9f9f9; 悬停效果 */
		background-color: rgb(92 191 146 / 82%);
	}
	.more {
		color: #007bff;
		/* 更多链接的颜色 */
		cursor: pointer;
	}
	
	.viewallreview {
		cursor: pointer;
		height: 48px;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
	}
	
	.viewallcomment {
		color: #01875f;
	}
	
	.newfunction {
		padding: 20px 0;
		max-width: 1200px;
		margin: 0 auto;
	}
	/* 评论内容结束 */
	/* 尾部开始 */
	footer {
		padding: 20px 0;
		margin-top: 40px;
		font-size: 0.9em;
		color: #666;
		text-align: left;
	}
	
	footer .footer-links {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 10px;
	}
	
	.googleplay {
		display: flex;
		flex-direction: column;
		width: 200px;
	}
	footer .childandhome {
		display: flex;
		flex-direction: column;
	}
	
	footer .footer-links span {
		color: #000;
		padding: 8px 0;
		margin: 0 10px;
	}
	
	footer .footer-links a {
		color: #666;
		text-decoration: none;
		padding: 8px 0;
		margin: 0 10px;
	}
	
	.privacy {
		display: flex;
		justify-content: space-between;
		margin-top: 32px;
	}
	
	footer .footer-links a:hover {
		/* text-decoration: underline; */
		color: #007bff;
	}
	
	
	
	footer .termsofservice {
		display: flex;
		justify-content: space-between;
		align-content: center;
		padding-bottom: 10px;
	}
	
	footer .termsofservice span {
		margin-right: 17px;
	}

	
	
/* 针对屏幕宽度大于768px的设备 */
@media screen and (min-width: 769px) {
	
.container a {
			padding: 10px 0;
	}
	.information h1 {
		font-size: 37px;
	}

	.container .appicon img {
		width: 200px;
		height: 200px;
		border-radius: 20px;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
	}
	.carousel-image {
		min-width: 20%;
		/* 每次显示3张图片 */
		margin-right: 16px;
		box-sizing: border-box;
		border-width: 0;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
		border-radius: 8px;
		cursor: pointer;
		height: 296px;
		max-width: 30%;
		/* min-width: 92px; */
	}
}



/* 针对屏幕宽度在601px到768px之间的设备 */
@media screen and (min-width: 601px) and (max-width: 768px) {

	.information h1 {
		font-size: 37px;
	}

	.container .appicon img {
		width: 180px;
		height: 180px;
		border-radius: 20px;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
	}




	.carousel-image {
		min-width: 20%;
		/* 每次显示3张图片 */
		margin-right: 16px;
		box-sizing: border-box;
		border-width: 0;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
		border-radius: 8px;
		cursor: pointer;
		/* height: 296px; */
		max-width: 50%;
		/* min-width: 92px; */
	}
}



/* 针对屏幕宽度小于600px的设备 */
@media screen and (max-width: 600px) {
	/* 中间部分 */
	main {
		display: flex;
		/* height: calc(100vh - 64px); */
		/* 高度为视口高度减去64px */
		/* padding-top: 64px; */
		/* 上边距为64px */
		box-sizing: border-box;
		flex-direction: column;
		max-width: 1200px;
		/* 版心最大宽度，可以根据需求调整 */
		margin: 0 auto;
		/* 水平居中 */
		padding: 10px 20px;
		/* 添加内边距，避免内容紧贴页面边缘 */
		/* margin-top: 80px; */
	}

	.appnameinfo,.app-rating-download{
		width: 100%;
	}

	.secondary-actions .icon_with_text {
		display: flex;
	}

	.app-actions {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 20px;
	}
	.install-btn {
		min-width:auto;
		height: auto;
		padding: 8px;
		font-size: 12px;
		background-color: #01875f;
		color: white;
		border: none;
		border-radius: 21px;
		/* margin-right: 15px; */
		/* 设置最小宽度为 200px */
		/* width: auto; */
	}





	.container .appicon img {
		width: 40px;
		height: 40px;
		border-radius: 7px;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
		display: none;
	}

	.container .appicon_left {
		width: 100%;
		display: flex;
		justify-content: flex-start;
        align-items: center;
		padding: 10px 0 30px 0;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
	}

	.appiocns {
		display: block;
	}


	.carousel-image {
		margin-right: 16px;
		box-sizing: border-box;
		border-width: 0;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
		border-radius: 8px;
		cursor: pointer;
		height: 204px;
		max-width: 30%;
	}
footer {
		margin-top: 0px;
	}

	.privacy {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 32px;
	}

	.privacy .language-switcher {
		margin-top: 10px;
	}
    .carousel-container {
		border-bottom: 1px solid #ccc;
		padding-bottom: 24px;
        height: 210px;
	}

}
/* 安装提示弹窗 */
.modal {  
	    display: flex;   
	    position: fixed;   
	    z-index: 1;   
	    left: 0;  
	    top: 0;  
	    width: 100%;   
	    height: 100%;   
	    overflow: auto;    
	    background-color: rgba(0,0,0,0.4);   
		   align-items: center;
		   justify-content: center;
	}  
	  
	.modal-content {  
		box-sizing: border-box;
	    background-color: #fefefe;  
	    display: flex;
		 flex-direction: column;
		 align-items: center;
		 justify-content: center;
	    padding: 20px;  
	    border: 1px solid #888;  
		border-radius: 8px;
	    width: 80%;   
	    max-width: 500px;  
	    /* text-align: center;  */
	    position: relative;  
	}  
	  
	.close {  
	    color: #aaa;  
	    float: right;  
	    font-size: 28px;  
	    font-weight: bold;  
	}  
	  
	.close:hover,  
	.close:focus {  
	    color: black;  
	    text-decoration: none;  
	    cursor: pointer;  
	}
	.modal-content .logo {
	    width: 166px;
	}
	
	.modal-content .title {
	    color: #000;
	    font-size: 17px;
	    line-height: 24px;
	    margin-top: 32px;
	    text-align: center;
	}
	.block {
		box-sizing: border-box;
	    background-color: #fff;
	    border-radius: 15px;
	    box-shadow: 0 0 10px rgba(0, 0, 0, .23);
	    display: flex;
	    margin: 15px 0;
	    padding: 20px;
	    width: 100%;
	}
	.block__icon {
	    border-radius: 20%;
	    height: 70px;
	    -o-object-fit: cover;
	    object-fit: cover;
	    width: 70px;
		margin-right: 20px;
	}
	.block__info {
	   display: flex;
	   flex-direction: column;
	   justify-content: space-around;
	}
	.block__info-title {
	    line-height: 24px;
	    overflow-wrap: anywhere;
	    word-break: keep-all;
	}
	.block__info-title span {
	    color: #000;
	    font-size: 22px;
	    font-weight: 500;
	}
	.block__info-title img {
	    display: inline-block;
	    height: 20px;
	    margin-left: 3px;
	    vertical-align: baseline;
	    width: 20px;
	}
	
	.block__info-description {
	    color: #028760;
	    font-size: 15px;
	    font-weight: 500;
	    margin: 5px 0;
	}
	.block__info-play {
	    align-items: center;
	    color: #333;
	    display: flex;
	    font-size: 10px;
	    font-weight: 500;
	    height: auto;
	    min-height: 14px;
	    white-space: normal;
	    word-break: break-all;
	}
	.block__info-play img {
	    margin-right: 4px;
	    width: 10px;
	}
	.detail {
	    align-items: center;
	    display: flex;
	    font-size: 14px;
	}
	.detail img {
	    width: 70px;
	}
	.detail__introduction {
	    color: #000;
	    margin-left: 12px;
	}
	.detail p {
	    line-height: 18px;
	    margin: 10px 0;
	}
	.bold {
	    color: #028760;
	    font-weight: 500;
	}
	.btn {
	    align-items: center;
	    background-color: #028760;
	    border-radius: 8px;
	    color: #fff;
	    display: flex;
	    font-size: 14px;
	    height: 35px;
	    justify-content: center;
	    margin-top: 15px;
	    width: 100%;
	}
  
  </style>