<template>
  <div>
    <component :is="currentPage"></component>
    <!-- 动态加载组件 -->
  </div>
</template>
  
  <script>
 import Page1 from './Page1.vue'; // 示例组件
import Page2 from './Page2.vue'; // 示例组件
import Page3 from './Page3.vue'; // 示例组件
import Page4 from './Page4.vue'; // 示例组件
import Page5 from './page5.vue'; // 示例组件
import Page6 from './Page6.vue'; // 示例组件
import { useCounterStore } from '@/stores/index';
  
  export default {
    data() {
      return {
        currentPage: Page1,  // 默认为 Home 页面
        userAgent:null,
        phonetype:'android',
      };
    },
    created() {
        const counter = useCounterStore();
        this.userAgent=navigator.userAgent || navigator.vendor || window.opera;
       console.log(123,this.phonetype);
      //  if (/iPad|iPhone|iPod/.test(this.userAgent) && !window.MSStream) {
      //   this.phonetype = 'ios';
      //   this.currentPage=Page
      // }else{
      //   this.phonetype = 'android';
        if(counter.model=='模板一'){
            this.currentPage=Page1
        }else if(counter.model=='模板二'){
            this.currentPage=Page2
        }else if(counter.model=='模板三'){
            this.currentPage=Page3
        }else if(counter.model=='模板四'){
            this.currentPage=Page4
        }else if(counter.model=='模板五'){
            this.currentPage=Page5
        }else{
            this.currentPage=Page6
        }
      //  }
        
  },
  };
  </script>