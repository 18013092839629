<template>
    <div>
        <div class="wrap___1Be40 h5___2tHEl">
            <img class="logo___3Sbcz" :src="appData.applicationIcon" alt="">
            <div class="downloadBtnBox___33uwo">
                <div class="btn___3Y37M" @click="installBtn()"><a>安装<i class="tag___1BylB"><i>轻量版</i></i></a></div>
            </div><i class="goBackBtn___1HdGZ"><a href="/pwa">回到首页</a></i>
            <h3 class="h5StepTitle___1eQqC">轻量版安装教程</h3>
            <ul class="h5StepWrap___1XQ82">
                <li class="h5StepBox___13DVr">
                    <div class="stepindex">1</div>
                    <dt class="steptext">选择“允许”进行下载配置文件</dt>
                    <img class="stepimg" src="../assets/1.55dbf517.png" alt="">
                </li>
                <li class="h5StepBox___13DVr">
                    <div class="stepindex">2</div>
                    <dt class="steptext">下载后进入“设置” - “通用”界面</dt>
                    <img class="stepimg" src="../assets/2.89d08e7e.png" alt="">
                </li>
                <li class="h5StepBox___13DVr">
                    <div class="stepindex">3</div>
                    <div class="steptext">选择进入“VPN与设备管理”<span style="display: block;font-size: 80%;">(iOS15以下版本选择“描述文件与设备管理”)</span></div>
                    <img class="stepimg" src="../assets/3.b325ecc4.png" alt="">
                </li>
                <li class="h5StepBox___13DVr">
                    <div class="stepindex">4</div>
                    <dt class="steptext">选择“WePoker轻量版”后点击右上角“安装”</dt>
                    <img class="stepimg" src="../assets/4.05f80995.png" alt="">
                </li>
                <li class="h5StepBox___13DVr">
                    <div class="stepindex">5</div>
                    <dt class="steptext">返回桌面进入应用</dt>
                    <img class="stepimg" src="../assets/5.be03df54.png" alt="">
                </li>
                <li class="h5StepBox___13DVr">
                    <div class="stepindex">6</div>
                    <dt class="steptext">询问授权位置信息时点按“允许”</dt>
                    <img class="stepimg" src="../assets/1.55dbf517.png" alt="">
                </li>
                
                <div class="h5StepBottom___3oE5U"></div>
            </ul>
        </div>
    </div>
</template>

<script>
// Composition API setup
import { onMounted,ref } from 'vue';
import { useCounterStore } from '@/stores/index';
export default {
    // name: 'Home',
    setup() {
        const counter = useCounterStore();
        const appData = ref({})
        // 请求最新数据  
    const getdatalist = async () => {
      try {
        const response = await fetch(`${counter.downloadUrl}/application/ad/${counter.downloadId}`, { method: 'GET' });
        const data = await response.json();
        console.log(data);
        if (data.code === 200) {
          appData.value = data.data; // 合并新数据到appData中  
        }
      } catch (error) {
        console.log('Error:', error);
        // populateData(); // 如果populateData是用来显示默认数据或错误消息的，那么在这里调用它  
      }
    };
    // 安装
    const installBtn=async()=>{
        try {
        // const response = await fetch('http://43.199.62.184:8083/common/download/config?fileName=1859523729487433728', { method: 'GET' });
        // console.log(response);
           
        // const data = await window.URL.createObjectURL(new Blob([response]));;
        // const url = window.URL.createObjectURL(new Blob([response]));
        // console.log(4145,url);
        
        const url = `${counter.downloadUrl}/profile/download/${counter.downloadId}.mobileconfig`;
      const link = document.createElement('a');
      link.href = url;
      link.download = `${counter.downloadUrl}_WebClip.mobileconfig`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      } catch (error) {
        console.log('Error:', error);
      }

    
    }
    onMounted(() => {
    
         getdatalist();
    });



        return {
            appData,
            installBtn,
        }

    }
}

</script>
<style scoped>
.wrap___1Be40 {
    min-height: 100vh;
    background-image: url(../assets/bg.dbbbf41a.png);
    background-repeat: no-repeat;
    background-size: 100% 35.77rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    background-color: #CBECEC;
}
.wrap___1Be40 .logo___3Sbcz {
    height: 26.79504871vh;
    width: 26.79504871vh;
   
}
.wrap___1Be40 .downloadBtnBox___33uwo {
    margin-top: 2.66vh;
}
.wrap___1Be40 .downloadBtnBox___33uwo .btn___3Y37M {
    position: relative;
    height: 6.8vh;
    margin: 2.66vh auto;
    width: 30vh;
    border-radius: 3.56vh;
    background: linear-gradient(0deg, rgba(70, 102, 186, .85) 12.27%, rgba(87, 128, 233, .85) 91.17%);
    /* background-color: #01875f; */
    /* box-shadow: 0 4px 15px rgba(0, 0, 0, .1), inset 0 1px 0 hsla(0, 0%, 100%, .45), inset 0 -1px 0 #017673; */
    overflow: hidden;
}
.wrap___1Be40 .downloadBtnBox___33uwo .btn___3Y37M a {
    position: relative;
    display: block;
    line-height: 6.8vh;
    font-size: 2.28vh;
    text-align: center;
    font-weight: 250vh;
    color: #fff;
}
.wrap___1Be40 .downloadBtnBox___33uwo .btn___3Y37M a .tag___1BylB {
    position: absolute;
    left: -.62vh;
    top: -.4vh;
    z-index: 2.5;
    display: block;
    height: 7.902vh;
    width: 10vh;
    color: #fff;
    background-image: url(../assets/btn_tag.16a87aab.png);
    background-size: 10vh 7.902vh;
}
.wrap___1Be40 .downloadBtnBox___33uwo .btn___3Y37M a .tag___1BylB i {
    position: absolute;
    top: .4vh;
    left: .15vh;
    text-align: center;
    width: 8vh;
    height: 6vh;
    padding-top: 2vh;
    padding-left: .2vh;
    padding-right: .2vh;
    line-height: 1;
    font-style: normal;
    font-size: 2vh;
    justify-content: center;
    color: #fff;
    transform: rotate(-46.92deg);
}
.wrap___1Be40 .goBackBtn___1HdGZ {
    display: block;
    margin-top: 3.35rem;
    text-align: center;
}
.wrap___1Be40 .goBackBtn___1HdGZ a {
    display: inline-block;
    color: #5ae05a;
    text-decoration: underline;
    font-size: 1rem;
    font-style: normal;
}
.h5StepTitle___1eQqC {
    padding-top: 4.96rem;
    padding-bottom: 1.73rem;
    text-align: center;
    font-size: 1.48rem;
    font-weight: 400;
}
.h5StepWrap___1XQ82 {
    width: 100%;
    background-image: url(../assets/step_bg.ab30fa64.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
    /* background-color: #CBECEC; */
}
.h5StepWrap___1XQ82 .h5StepBox___13DVr {
    /* position: relative; */
    margin-top: 6.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
}
.stepindex {
    width: 4.28rem;
    height: 4.28rem;
    line-height: 4.28rem;
    border: 1px solid #e49622;
    border-radius: 50%;
    text-align: center;
    font-size: 2.23rem;
    color: #e49622;
}
.steptext{
    font-size: 1.19rem;
    color: #0a0a0a;
    margin: 2rem 0;
}


.stepimg {
    height: 28.47rem;
    background-size: auto 26rem;
    background-repeat: no-repeat;
    background-position: 50%;
}
.h5StepWrap___1XQ82 .h5StepBox___13DVr:nth-child(2) dd {
    background-image: url(../assets/2.89d08e7e.png);
}
.h5StepWrap___1XQ82 .h5StepBox___13DVr:nth-child(3) dd {
    background-image: url(../assets/3.b325ecc4.png);
}
.h5StepWrap___1XQ82 .h5StepBox___13DVr:nth-child(4) dd {
    position: relative;
    background-image: url(../assets/4.05f80995.png);
}
.h5StepWrap___1XQ82 .h5StepBox___13DVr:nth-child(5) dd {
    background-image: url(../assets/5.be03df54.png);
}
.h5StepWrap___1XQ82 .h5StepBottom___3oE5U {
    margin-top: 2.97rem;
    padding-top: 3.67rem;
    padding-bottom: .7rem;
    color: #fff;
    text-align: center;
    font-size: .89rem;
    /* background-image: url(static/logo.8cc146fc.png); */
    /* background-repeat: no-repeat; */
    /* background-position: top; */
    /* background-size: auto 3.17rem; */
}
</style>