<template>
    <div>
        <!-- 头部 -->
        <!-- <header>
            <img class="googleicon" src="../assets/icons.jpg" alt="" />
            <h1 style="
          border: 0;
          color: #5f6368;
          font: inherit;
          margin: 0;
          font-size: 22px;
          padding: 0;
          vertical-align: baseline;
        ">
                Pwa Store
            </h1>
        </header> -->
        <!-- 中间部分 -->
        <main>
            <section class="fistblock" style="">
                <!-- app信息模块 -->
                <div class="container">
                    <video v-if="appData.bannertype==2" style="width: 100%;" autoplay muted :src=appData.topbanner loop class="containerbg"></video>
                    <img v-else :src="appData.topbanner" alt="" class="containerbg" />
                    <!-- 公司信息 -->
                    <div class="information">
                        <div class="appicon_left">
                            <img :src="appData.applicationIcon" alt="" id="app-icon" />
                            <div class="softwarename">
                                <h1 itemprop="name" class="app-title" data-key="softwarename">
                                    {{ appData.application }}
                                </h1>
                                <a href="https://ddos.blockiot.com.cn/" class="companyinfo"><span id="company-name">{{
                                        appData.company }}</span></a>
                            </div>
                        </div>
                        <div class="appnameinfo">
                            <!-- <div class="advertising">
                  <span>包含广告</span><span>·</span><span>应用内购商品</span>
                </div> -->
                            <div class="app-rating-download">
                                <div class="rating">
                                    <span id="views">{{ appData.reviews }}</span>
                                    <span>Views</span>
                                </div>
                                <div class="downloads">
                                    <span id="download-number">{{ appData.downloadNumber }}</span>
                                    <span>Downloads</span>
                                </div>
                                <div class="age-rating">
                                    <span id="reviews1">{{ appData.reviews }}</span>
                                    <span>Reviews</span>
                                </div>
                            </div>
                            <div class="app-actions">
                                <button class="install-btn" id="install-btn" style="display: none">
                                    Install
                                </button>
                                <button class="install-btn" id="play-btn" style="display: none" @click="goplay">
                                    play
                                </button>
                                <div class="sharebox">
                                    <div class="share_out">
                                        <n-icon size="30">
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 512 512" enable-background="new 0 0 512 512"
                                                xml:space="preserve">
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M383.822,344.427c-16.045,0-31.024,5.326-41.721,15.979l-152.957-88.42c1.071-5.328,2.142-9.593,2.142-14.919
			c0-5.328-1.071-9.593-2.142-14.919l150.826-87.35c11.762,10.653,26.741,17.041,43.852,17.041c35.295,0,64.178-28.766,64.178-63.92
			C448,72.767,419.117,44,383.822,44c-35.297,0-64.179,28.767-64.179,63.92c0,5.327,1.065,9.593,2.142,14.919l-150.821,87.35
			c-11.767-10.654-26.741-17.041-43.856-17.041c-35.296,0-63.108,28.766-63.108,63.92c0,35.153,28.877,63.92,64.178,63.92
			c17.115,0,32.089-6.389,43.856-17.042l151.891,88.421c-1.076,4.255-2.141,8.521-2.141,13.847
			c0,34.094,27.806,61.787,62.037,61.787c34.229,0,62.036-27.693,62.036-61.787C445.858,372.12,418.052,344.427,383.822,344.427z">
                                                        </path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </n-icon>
                                        <span> 分享 </span>
                                    </div>
                                    <div class="share_out">
                                        <n-icon size="30">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path
                                                        d="M23 6.5a5.5 5.5 0 1 0-11 0a5.5 5.5 0 0 0 11 0zM18 7v2.504a.5.5 0 1 1-1 0V7h-2.505a.5.5 0 0 1 0-1H17V3.5a.5.5 0 0 1 1 0V6h2.496a.5.5 0 0 1 0 1H18zm-.5 12.782V13a6.52 6.52 0 0 0 1.5-.174v8.42a.75.75 0 0 1-1.188.608l-5.81-4.181l-5.812 4.18a.75.75 0 0 1-1.188-.608V6.25A3.25 3.25 0 0 1 8.252 3h3.77a6.468 6.468 0 0 0-.709 1.5H8.252a1.75 1.75 0 0 0-1.75 1.75v13.533l5.061-3.642a.75.75 0 0 1 .876 0l5.061 3.642z"
                                                        fill="currentColor"></path>
                                                </g>
                                            </svg>
                                        </n-icon>

                                        <span>添加至愿望清单</span>
                                    </div>
                                </div>
                                <div style="color: #9aa0a6; display: flex; align-items: center">
                                    <n-icon size="25">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 28 28">
                                            <g fill="none">
                                                <path
                                                    d="M9.747 10a2.25 2.25 0 0 1 2.244 2.096l.006.154v11.5A2.25 2.25 0 0 1 9.9 25.995L9.747 26H4.25a2.25 2.25 0 0 1-2.245-2.096L2 23.75v-11.5a2.25 2.25 0 0 1 2.096-2.245L4.25 10h5.497zm0 1.5H4.25a.75.75 0 0 0-.743.648l-.007.102v11.5c0 .38.282.694.648.743l.102.007h5.497a.75.75 0 0 0 .743-.648l.007-.102v-11.5a.75.75 0 0 0-.649-.743l-.101-.007zm-1.997 10a.75.75 0 0 1 .102 1.493L7.75 23H6.249a.75.75 0 0 1-.102-1.493l.102-.007H7.75zM23.753 2a2.25 2.25 0 0 1 2.244 2.096l.006.154V15.5a2.25 2.25 0 0 1-2.096 2.245l-.154.005h-4.755v2.75h1.252a.75.75 0 0 1 .102 1.493L20.25 22H13v-1.5h4.498v-2.75H13v-1.5h10.753a.75.75 0 0 0 .743-.648l.007-.102V4.25a.75.75 0 0 0-.649-.743l-.101-.007H7.25a.75.75 0 0 0-.743.648L6.5 4.25V9H5V4.25a2.25 2.25 0 0 1 2.096-2.245L7.25 2h16.503z"
                                                    fill="currentColor"></path>
                                            </g>
                                        </svg>
                                    </n-icon>

                                    <span style="margin-left: 10px">devices This app is available for your device</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- app图标 -->
                    <div class="appicon">
                        <img :src="appData.applicationIcon" alt="" id="app-icon2" />
                    </div>
                </div>
                <!-- 轮播图模块 -->
                <div class="carousel-container">
                            <n-carousel :slides-per-view="bannernumber" :space-between="10" :loop="false" draggable>
                                <img class="carousel-images" :src="item" alt="" v-for="item in appData.detailImages"
                                    :key="item" />
                            </n-carousel>
                        </div>
            </section>

            <section style="padding: 10px 20px">
                <!-- 中间部分模块 -->
                <div class="appcontent">
                    <!-- 大屏左边部分 -->
                    <section class="content_left">
                        

                        <!-- 关于此应用 -->
                        <div class="aboutappliction" id="about">
                            <section class="aboutapp">
                                <h2 class="avoutthisapp">About this application</h2>
                                <span class="iconfont icon-youjiantou"></span>
                            </section>
                            <section class="appdetail">
                                <p class="fontcontent" id="about-description">
                                    {{ appData.introduction }}
                                </p>
                            </section>
                            <section class="updatetime">
                                <p>updated on：</p>
                                <p id="updata">{{ appData.updateTime }}</p>
                            </section>
                        </div>

                        <!-- 评分和评论 -->
                        <div class="Ratingreviews">
                            <section class="aboutapp">
                                <h2 class="avoutthisapp">Ratings and Reviews</h2>
                                <span class="iconfont icon-youjiantou"></span>
                            </section>
                            <div class="rating-section">
                                <!-- <div class="device-selector">
                    <button class="device active">手机</button>
                    <button class="device">手表</button>
                    <button class="device">电视</button>
                    <button class="device">平板电脑</button>
                  </div> -->
                                <!-- 评分进度条 -->
                                <div class="rating-summary">
                                    <div class="rating-score">
                                        <div class="score" id="score">{{ appData.score }}</div>
                                        <div class="stars">★★★★★</div>
                                        <div class="reviews" id="reviews"></div>
                                    </div>
                                    <div class="rating-distribution">
                                        <div class="rating-bar">
                                            <span class="label">5</span>
                                            <div class="bar">
                                                <div class="fill" style="width: 80%"></div>
                                            </div>
                                        </div>
                                        <div class="rating-bar">
                                            <span class="label">4</span>
                                            <div class="bar">
                                                <div class="fill" style="width: 60%"></div>
                                            </div>
                                        </div>
                                        <div class="rating-bar">
                                            <span class="label">3</span>
                                            <div class="bar">
                                                <div class="fill" style="width: 40%"></div>
                                            </div>
                                        </div>
                                        <div class="rating-bar">
                                            <span class="label">2</span>
                                            <div class="bar">
                                                <div class="fill" style="width: 20%"></div>
                                            </div>
                                        </div>
                                        <div class="rating-bar">
                                            <span class="label">1</span>
                                            <div class="bar">
                                                <div class="fill" style="width: 30%"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 评论内容 1-->
                                <div id="reviews-container" v-if="appData.reviewFormList.length != 0">
                                    <div v-for="item in appData.reviewFormList" :key="item.id" class="review">
                                        <div class="review-header">
                                            <div class="bgpic">
                                                <img :src="item.icon" :alt="item.name" class="pic" />
                                                <span class="review-author">{{ item.name }}</span>
                                            </div>
                                            <span class="more">more</span>
                                        </div>

                                        <div class="commentdata">
                                            <!-- <span class="review-rating">★★★★☆</span> -->
                                            <span class="review-date">{{ item.createTime }}</span>
                                        </div>
                                        <div class="review-content">
                                            <p class="fontcontent">
                                                {{ item.review }}
                                            </p>
                                        </div>
                                        <div class="review-footer">
                                            <span class="helpful-count">26,542 people found this review useful</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="viewallreview">
                                    <span class="viewallcomment">see all comment</span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- 大屏右边部分 -->
                    <!-- <section class="content_right"></section> -->
                </div>
            </section>
            <!-- 尾部 -->
            <footer>
                <div class="privacy">
                    <p class="termsofservice">
                        <a href=""><span>Terms of Service</span></a>
                        <a href=""><span>privacy</span></a>
                        <a href="#about"><span>About App</span></a>
                    </p>
                    <!-- <div id="language-switcher">
              <button id="en-button">English</button>
              <button id="zh-button">中国 (简体中文)</button>
            </div> -->
                </div>
            </footer>
        </main>
        <!-- 遮罩层 -->
        <!-- <div class="zhezhao" id="colse-bady" v-if="dialogadvice">
        <div>
  
        </div>
        <img class="closeImg" id="close" @click="dialogadvice = false"
          src="https://img.pwaload.com/assets/imgs/index2/gb.png">
        <div class="top">
          <img width="100%" src="https://img.pwaload.com/assets/imgs/index2/top_di.png">
        </div>
        <div class="zhuanpan" @click="rotating">
          <div class="di">
            <img src="https://img.pwaload.com/assets/imgs/index2/zuanpandi2.png" alt="">
          </div>
          <div class="di2" id="rotatingImage" :style="imageStyle" ref="di2">
            <img src="https://img.pwaload.com/assets/imgs/index2/zuanpandi_1.png" alt="">
          </div>
          <div class="di3" style="transition: transform 4s">
            <img src="https://img.pwaload.com/assets/imgs/index2/zq_xzk.png" alt="">
          </div>
          <div class="content wapper">
            <img src="https://img.pwaload.com/assets/imgs/index2/zq_anniu.png" alt="">
            <span id="numText">0</span>
          </div>
          <div class="yuanbao">
            <img width="60%" src="https://img.pwaload.com/assets/imgs/index2/zq_yb.png">
          </div>
        </div>
        <div class="buttom">
          <img style="width: 100%;" src="https://img.pwaload.com/assets/imgs/index2/top_di2.png">
        </div>
        <div class="text-box" id="zhuanpandec">{{ appData.introduction }}</div>
      </div> -->
        <!-- 提示弹窗 -->
        <div id="modal" class="modal" v-show="showadvice" @click="showadvice = false">
            <div class="modal-content" @click.stop="">
                <img src="../assets/img_reward-DGauptCK.png" alt="logo" loading="lazy" class="logo" />
                <div>
                    <p>
                        We are collecting
                        <span style="color: #028760">objective</span> reviews for this apps
                    </p>
                </div>
                <div class="block">
                    <img id="dialoglogo" :src="appData.applicationIcon" loading="lazy" alt="icon" class="block__icon" />
                    <div class="block__info">
                        <div class="block__info-title">
                            <span id="dialogtitle">{{ appData.application }}</span>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAA+VBMVEUAAAABf/AAfu8Alf8AfvAAfvAAf/AAgvQAfvAAfu8AfvAAf+8Af/AAgO8Af/AAgPEAgPIAgPAAgvEAgfQAg/QAgO8Ag/8AgPAAf/AAfu8Af/AAf/AAf/AAf/AAfu8Af/AAfu8Af/AAgPAAf/EAgPEAgO8AgPEAgO8AgfIAgPIAhPYAfvAAgfEAgPAAfu////8Jg+/6/P71+f6l0flAnvMZivC/3/us1fmay/hyt/YmkfEQhvDp9P3c7fzM5fvH4/tlsfVKo/M1mPIulfIhjvH+/v7w9/7t9f3j8P3V6fy42/qx1/qPxveHwvd/vvZ7vPZcrPSNxfdTp/Q9lj0RAAAALnRSTlMA/uUF8cudF+HUurGrk3lcTEQzLCAQDGT58uvXzcfCvbSXjH18cmxAOigdZUdGwVZ1LgAAAqNJREFUWMPVmGlX4jAUhknLooKsKuK+o6hvWGVHFMZxd2b+/48Z60AoJ02aNqdn8PnWcPocArn35t7Q92bNdFo113zqNiIwUvxyykBkw5cwiU92s/OL2V1rNenHZxqwINHYbC0WJbAwTB/CFCaETyevm6dhTEj5EEbA2L62Fq63wYh4953DTjyTicPOuWdhAlISno8ggRTi9TAewYUjj4c6DBfC3g73GVw58+K72IIrWxcKolg2nT8+jO8QKEB24ofH+XQ2JnBFIwaBL4gRifLWFWixwgmXoMUSJzyAFgec8ARanHDCDLTIcMJ1Ag3IeohjGRosh3ii0CDqIMxDg7yDMAd1Og3Mkws5UIAid4+UDkawUQg5sQ81fv2gn/QamLHvKFxV/H4V+sU7Zqwyiff0UK/SfzQFqYGRhAqjBzrhN2wk/W543Jv6Kh1AsmkzARUafTpliHkS5lwg70GF7hPzfXAf7tnCuaQWx+Vb5nt1iucSixEDKpBn5qvBCWMSL8Ww8/vt235tbHuuMd8LERT/ouVLb8KRr/erdfbcYr6bMgRspsXVqU7pnPGN+QZdSa0SC9/pxHgPizbz9RuQCMVbZoaHe5YQLB7HELOZFv8pncpU8XOEu9lDB2LCRdmx+aBTesMqnf0AYoyc/GC/Uo5KXVaoSm6h1+J8f8AQhR5LDirGIcQkTLf0xYKD0YaYVbUES5p0xhvEJFVLAHlhvpbsdqhepMrP0xJCZBv2UEbLN5KEIC+jBYiNA97nWuhzkkT91IWcnKfLUrfZgAv5wK9z+hfOoK/E+pf2oNsK/cYn6NZMv3kMvL1lDfiltwb8km/A9UcEwQ8x9McswQ+C9EdV+sO0/z/umxtIXlkLV/aB5CKMTOVD3UUYO0sH44sxul8Q/gJGzKdZ1baZgQAAAABJRU5ErkJggg=="
                                alt="yanzheng" loading="lazy" />
                        </div>
                        <!-- <p class="block__info-description" id="dialogdec">{{ appData.introduction }}</p> -->
                        <p class="block__info-play">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAoCAMAAACsAtiWAAAAe1BMVEUAAAAuqHoWkG8Ch2AEiGEPjWoCh2ACh2AEimECiGADh2ECiGECh2ADh2EDh2ACiGEDiGIDiGECh2EDh2ACiGACh2ADh2ACh2IDiGIDiGIEiGMGimMHjmMKjGMCh2EFiWMHimQCh2ECiGEDiGACiGACh2EDiGEEh2ACh2ACKEGFAAAAKHRSTlMABQv6jRDs2TzosWnlyLd8VU7ew/XSl3ReSkIpJBfVLyLzzZxvZqOCBWgBPgAAAWpJREFUOMu1lFmSozAQBQsJxL6Zzfvumbz/Cccm2qbVqO2vyQ8URWQ8hQpKYrMujCnW8jt1zkhei5uqg7jc7coYukpmeMsQsquSO+qaQbj0bCFdwOLmverbo04nSQ0Z7JeenbqHbFBjEZQGzo69qzOYMhA5ZtCsxMmqgewoBc2bPqwbCmlR8gZFKyBvgf+gXHSy+aAA/Dl+VND97pMCrR98UuBUqm9KzG6uQHyQkQAjZw4uhVJGNnSSsHIp4Vf2mkJ8yrmi/z5/niu91DQzpZgamFBJoLWylXgpL7wTgUhObSl9IBM1+f05kDxfnKCL5DsFw+PkLc/PknaDPRkb2jHzgi+/cKEf1wN64zYOWm9lpKdxK/krP4i5uYyU7NWPCh3NjUhTTZWP2f40tsY+RsJi+8NYkNgTlWMiaxdDrsR2EnTqTZeJJplPqQ/71de473G3sw4h9KPIf6y1OPHSmJF42nIu3SPuQbbwD+I5Le1jo0NVAAAAAElFTkSuQmCC"
                                loading="lazy" alt="selaf" class="ic_dun" />
                            Verified by Play Protect
                        </p>
                    </div>
                </div>
                <div class="detail">
                    <img src="../assets/yhj.png" alt="yhj" loading="lazy" />
                    <div class="detail__introduction">
                        <p>
                            If you can <span class="bold">download the app</span> and leave an
                            <span class="bold">objective</span> review and rating, You will
                            have the chance to receive a <span class="bold">$20</span>
                            Google Play gift card.
                        </p>
                    </div>
                </div>
                <div class="btn" @click.stop="showadvice = false">Install</div>
                <!-- <span class="close">&times;</span>  -->
                <!-- <p>欢迎回来！</p> -->
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { useCounterStore } from '@/stores/index';

export default {
    // name: 'Home',
    setup() {
        // 使用 ref 创建响应式数据  

        const counter = useCounterStore();
        // const downloadUrl = ref('https://test.nikozhou.com:82/prod-api'); // 下载地址（服务器IP地址）  
        // const downloadId = ref('1837097112395976704'); // 参数ID  
        const appData = ref({
            application: "", // APP名称  
            language: 0,
            applicationIcon: "", // APP图标  
            company: "", // 公司名称  
            viewNumber: "",
            score: "4.5",
            downloadNumber: "", // 下载数量  
            reviews: "", // 评论数  
            updateTime: "",
            detailImages: [], // app详情图片  
            introduction: "", // APP介绍  
            reviewFormList: [
                {
                    icon: "", // 用户头像  
                    name: "", // 用户名  
                    createTime: "",
                    review: "" // 用户评论  
                }
            ]
        });
        // let showpalybtn=ref(true)
        // let deferredPrompt = null;
        // 安装

        // 请求最新数据  
        const getdatalist = async () => {
            try {
                const response = await fetch(`${counter.downloadUrl}/application/ad/${counter.downloadId}`, { method: 'GET' });
                const data = await response.json();
                console.log(data);
                if (data.code === 200) {
                    appData.value = data.data; // 合并新数据到appData中  
                }
            } catch (error) {
                console.log('Error:', error);
                // populateData(); // 如果populateData是用来显示默认数据或错误消息的，那么在这里调用它  
            }
        };
        // 轮播图
        // 创建一个响应式引用，用于存储屏幕宽度  
        let bannernumber = ref(5);
        // 定义一个函数，用于更新屏幕宽度  
        const updateScreenWidth = () => {
            console.log(window.innerWidth);

            if (window.innerWidth < 600) {
                bannernumber.value = 3;
            } else {
                bannernumber.value = 5;
            }
        };

        // 安装推荐弹窗
        let showadvice = ref(false)
        const resvedialog = () => {
            showadvice.value = 'true';
        };

        // 检查是否在 PWA 环境中

        if (window.matchMedia('(display-mode: standalone)').matches) {
            window.location.href = '/'; // 重定向到首页
            //    return false; // 阻止后续下载逻辑的执行
        } else {
            //发起统计预览数量请求 
            fetch(counter.downloadUrl + '/link/readCount/' + counter.downloadId, { method: 'POST' })
                .then(response => response.json())
                .then(data => { })
                .catch(error => console.error('Error:', error));
        }

        const goplay = () => {
            window.location.href = '/'
        }
        // 在组件挂载后执行  
        onMounted(() => {

            let ISios = navigator.userAgent || navigator.vendor || window.opera;
            let resa = /iPad|iPhone|iPod/.test(ISios) && !window.MSStream
            let qwe = document.querySelector('#install-btn');
            if (counter.urltype == 2 && resa == false) {
                qwe.style.display = 'block';
                qwe.textContent = 'Download';
                qwe.addEventListener('click', async () => {
                    try {
                        const url = counter.appurl;
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = '';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } catch (error) {
                        console.log('Error:', error);
                    }

                })
            } else if(counter.urltype == 1 && resa == true){
                qwe.style.display = 'block';
                qwe.textContent = 'IOS Clip';
                qwe.addEventListener('click', async () => {
                    window.location.href = '/ios'
                })
            }
            getdatalist();
            updateScreenWidth()
            window.addEventListener('resize', updateScreenWidth);
            console.log(window.matchMedia('(display-mode: standalone)'));

            if (!window.matchMedia('(display-mode: standalone)').matches) {
                // console.log('在pwa内');
                document.addEventListener('visibilitychange', resvedialog);
            }


        });
        onUnmounted(() => {
            document.removeEventListener('visibilitychange', resvedialog);
        });


        // 返回响应式数据和方法  
        return {
            appData,
            goplay,
            // 轮播图 
            bannernumber,
            // 弹窗推荐安装
            showadvice,
        };
    },
};
</script>
<style lang="css" scoped>
/* 全局样式 */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: white;
}

header {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

main {
    display: flex;
    /* height: calc(100vh - 64px); */
    /* 高度为视口高度减去64px */
    /* padding-top: 64px; */
    /* 上边距为64px */
    box-sizing: border-box;
    flex-direction: column;
    max-width: 1200px;
    /* 版心最大宽度，可以根据需求调整 */
    margin: 0 auto;
    /* 水平居中 */
    /* padding: 10px 20px; */
    /* 添加内边距，避免内容紧贴页面边缘 */
    /* margin-top: 80px; */
}

.fistblock {
    position: relative;
    background-color: #202124;
    padding: 20px 0 40px 0;
    padding-top: 150px;
}

main .container {
    text-align: center;
    /* 文本居中 */
    /* max-width: 90%; 控制内容的最大宽度 */
    width: 100%;
    /* 宽度100% */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    /* background: linear-gradient(to top, #202124, transparent); */
}

.containerbg {
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.container::after {
    content: "";
    width: 100%;
    height: 400px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: linear-gradient(to top, #202124, rgba(32, 33, 36, 0.1));
}

.container a {
    text-decoration: none;
    color: #00a173;
}

.container .information,
.softwarename,
.appnameinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.softwarename {
    justify-content: space-between;
    padding: 5px 0;
}

.appdetail,
.updatetime {
    color: #5f6368;
    font-size: 0.875rem;
}

.fontcontent {
    line-height: 1.25rem;
    word-break: break-all;
    text-align: left;
}

.updatetime {
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.container .information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    z-index: 2;
}

.app-rating-download {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.app-rating-download div {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    border-right: 1px solid #ccc;
}

.rating,
.downloads,
.age-rating {
    text-align: center;
    font-size: 14px;
    color: white;
}

.icon_with_text .icon-text {
    display: none;
    /* 隐藏文字 */
}

.app-actions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.install-btn {
    font-size: 20px;
    background-color: #01875f;
    color: white;
    border: none;
    border-radius: 15px;
    height: 48px;
    /* padding: 0 50px; */
    /* margin-right: 15px; */
    min-width: 200px;
    /* 设置最小宽度为 200px */
    width: auto;
}

.sharebox {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #00a173;
    padding: 20px 0px;
    font-size: 14px;
}

.share_out {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
}

/* .app-actions .secondary-actions {
    display: flex;
    align-items: center;
    gap: 20px;
} */

/* .secondary-actions span {
    font-size: 16px;
    color: #01875f;
    text-decoration: none;
    display: flex;
    align-items: center;
} */

/* .secondary-actions .icon-fenxiang {
    font-size: 50px;
}

.secondary-actions .icon-weibiaoti2fuzhi03 {
    font-size: 30px;
    font-weight: 800;
} */

.container .appicon_left img {
    display: none;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    margin-right: 10px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.app-title {
    color: #e8eaed;
}

.appcontent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0px 0px 0px;
}

.content_left {
    border-radius: 8px;
    width: 100%;
    /* height: 420px; */
}

.content_right {
    flex-basis: 25%;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* 轮播图模块开始 */

.carousel-container {
    margin-top: 24px;
    height: 420px;
    padding: 0 20px;
}

.carousel-images {
    /* display: flex;
		transition: transform 0.5s ease; */
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* 轮播图结束 */
/* 关于此应用开始 */
.aboutappliction,
.safesecurity,
.newfunction {
    margin-bottom: 24px;
}

.aboutappliction .aboutapp,
.Ratingreviews .aboutapp,
.newfunction h2 {
    padding-bottom: 20px;
}

.safesecurity .avoutthisapp,
.aboutappliction .avoutthisapp,
.Ratingreviews .avoutthisapp,
.newfunction h2 {
    font-weight: 400 !important;
    margin-right: 10px;
}

.newfunction p {
    color: #5f6368;
}

.aboutapp {
    display: flex;
    align-items: center;
}

.aboutapp span {
    font-size: 22px;
    font-weight: 800;
    color: #8d8d8d;
    padding: 13px;
    border-radius: 50%;
    /* 圆形背景 */
    transition: background-color 0.3s ease;
    /* 添加平滑过渡效果 */
}

.aboutapp span:hover {
    background-color: #ecebeb;
    /* 灰色背景 */
    cursor: pointer;
}

.differentlabels {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 12px;
    margin-top: 28px;
}

.tag {
    display: inline-block;
    padding: 4px 12px;
    /* 调整标签内的间距 */
    border: 1px solid #d9d9d9;
    /* 边框颜色 */
    border-radius: 20px;
    /* 圆角效果 */
    font-size: 0.875rem;
    /* 字体大小 */
    color: #666666;
    /* 文字颜色 */
    white-space: nowrap;
    /* 防止文字换行 */
    line-height: 1.5;
    /* 行高 */
    text-align: center;
    /* 文字居中 */
}

/* 关于此应用结束 */
/* 数据安全开始 */
.info-box {
    border: 1px solid #d9d9d9;
    /* 边框颜色 */
    border-radius: 8px;
    /* 圆角效果 */
    padding: 16px;
    /* 内边距 */
    padding-top: 0px;
    background-color: #fff;
    /* 背景颜色 */
    max-width: 600px;
    /* 最大宽度 */
    margin-top: 30px;
    /* 居中显示 */
}

.info-list {
    list-style: none;
    /* 去除列表项的默认样式 */
    padding: 0;
    margin: 0 0 16px 0;
}

.info-list li {
    display: flex;
    /* 使用flex布局 */
    align-items: center;
    /* 垂直居中 */
    margin-bottom: 12px;
    /* 列表项间距 */
    font-size: 0.875rem;
    /* 设置字体大小 */
    color: #666666;
    /* 字体颜色 */
    margin-top: 20px;
}

.info-list li a {
    text-decoration: underline;
    color: #666;
}

.info-list li:last-child {
    margin-bottom: 0;
    /* 最后一个列表项不需要额外的下间距 */
}

.info-list .nosharewith {
    display: flex;
    flex-direction: column;
}

.iconfont {
    font-size: 1.5rem;
    color: #4a4a4a;
    margin-right: 8px;
}

.details-link {
    font-size: 0.875rem;
    color: #01875f;
    text-decoration: none;
    margin: 10px 0;
}

.details-link:hover {
    text-decoration: underline;
}

/* 数据安全结束 */
/* 评分和评论 开始*/
.rating-section {
    display: flex;
    flex-direction: column;
    /* max-width: 600px; */
    font-family: Arial, sans-serif;
}

.device-selector {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.device {
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    background-color: #fff;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.device .active {
    background-color: #e0f2f1;
    border: none;
}

.device:hover {
    background-color: #f5f5f5;
}

.rating-summary {
    display: flex;
    gap: 16px;
    align-items: flex-start;
}

.rating-score {
    text-align: center;
}

.score {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
}

.stars {
    color: #01875f;
    font-size: 1.2rem;
}

.reviews {
    font-size: 0.875rem;
    color: #666;
    margin-top: 8px;
}

.rating-distribution {
    flex-grow: 1;
}

.rating-bar {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.rating-bar .label {
    width: 20px;
    font-size: 0.875rem;
    color: #666;
}

.bar {
    flex-grow: 1;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 8px;
}

.bar .fill {
    height: 100%;
    background-color: #01875f;
    border-radius: 5px;
}

/* 评分和评论 结束*/
/* 评论内容开始 */
.review {
    border-radius: 8px;
    padding: 24px 0;
    background-color: #fff;
}

.review2 .bgpic .pic {
    background-color: pink;
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bgpic {
    display: flex;
    align-items: center;
}

.pic {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    overflow: hidden;
    background-size: cover;
    background-color: orange;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    margin-right: 8px;
    color: white;
}

.review-author {
    font-weight: bold;
}

.commentdata {
    margin-top: 16px;
    display: flex;
}

.review-rating {
    color: #01875f;
    /* 星星的颜色 */
}

.review-date {
    font-size: 12px;
    color: #757575;
    /* 日期的颜色 */
}

.review-content {
    margin: 12px 0;
    line-height: 1.5;
    display: flex;
    word-break: break-all;
}

.review-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.helpful-count {
    color: #757575;
    /* 有用人数的颜色 */
}

.helpful-buttons {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 8px;
}

.helpfulask {
    color: #757575;
}

button {
    border: 1px solid #efefef;
    background-color: #fff;
    color: #333;
    padding: 5px 20px;
    border-radius: 17px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    /* background-color: #f9f9f9; 悬停效果 */
    background-color: rgb(92 191 146 / 82%);
}

.more {
    color: #007bff;
    /* 更多链接的颜色 */
    cursor: pointer;
}

.viewallreview {
    cursor: pointer;
    height: 48px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.viewallcomment {
    color: #01875f;
}

.newfunction {
    padding: 20px 0;
    max-width: 1200px;
    margin: 0 auto;
}

/* 评论内容结束 */
/* 尾部开始 */
footer {
    padding: 20px 0;
    margin-top: 40px;
    font-size: 0.9em;
    color: #666;
    text-align: left;
}

footer .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.googleplay {
    display: flex;
    flex-direction: column;
    width: 200px;
}

footer .childandhome {
    display: flex;
    flex-direction: column;
}

footer .footer-links span {
    color: #000;
    padding: 8px 0;
    margin: 0 10px;
}

footer .footer-links a {
    color: #666;
    text-decoration: none;
    padding: 8px 0;
    margin: 0 10px;
}

.privacy {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}

footer .footer-links a:hover {
    /* text-decoration: underline; */
    color: #007bff;
}

footer .termsofservice {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-bottom: 10px;
}

footer .termsofservice span {
    margin-right: 17px;
}

/* 遮罩层基础样式重置 */
.zhezhao {
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 4rem;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 1rem 0;
}

.closeImg {
    position: absolute;
    right: 0.5rem;
    width: 2rem;
    top: 0.5rem;
    z-index: 9999;
}

.zhezhao>.top,
.buttom {
    display: flex;
    align-items: center;
    width: calc(100% - 2rem);
    justify-content: space-around;
    margin-top: 0.2rem;
}

.zhuanpan {
    width: 23rem;
    height: 14.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.zhuanpan .di,
.di2,
.content,
.di3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zhuanpan>.di>img {
    margin: 0 auto;
    height: 100%;
}

.zhuanpan>.di2>img {
    margin: 0 auto;
    height: 88%;
}

.zhuanpan>.di3>img {
    height: 50%;
    position: absolute;
    top: 0;
}

.zhuanpan>.content>img {
    margin: 0 auto;
    height: 5rem;
}

.zhuanpan>.content>span {
    text-align: center;
    font-size: 2rem;
    color: #fff;
    position: absolute;
    font-weight: 600;
}

.zhezhao>.buttom {
    position: relative;
    margin-top: 2rem;
}

.zhezhao .yuanbao {
    position: absolute;
    z-index: 10;
    bottom: -2rem;
    right: 0;
}

.text-box {
    margin-top: 2rem;
    color: #ababab;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

/* 针对屏幕宽度大于768px的设备 */
@media screen and (min-width: 769px) {
    header {
        box-sizing: border-box;
        width: 100%;
        height: 64px;
        margin: 0 auto;
        padding: 0 40px;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: white;
    }

    header .googleicon {
        width: 50px;
        height: 50px;
        border-radius: 20%;
        margin-right: 10px;
    }

    .container a {
        padding: 10px 0;
    }

    .information h1 {
        font-size: 37px;
    }

    .container .appicon {
        z-index: 1;
    }

    .container .appicon img {
        width: 200px;
        height: 200px;
        border-radius: 20px;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
            0 1px 3px 1px rgb(60 64 67 / 15%);
    }

    .carousel-image {
        min-width: 20%;
        /* 每次显示3张图片 */
        margin-right: 16px;
        box-sizing: border-box;
        border-width: 0;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
            0 1px 3px 1px rgb(60 64 67 / 15%);
        border-radius: 8px;
        cursor: pointer;
        height: 296px;
        max-width: 30%;
        /* min-width: 92px; */
    }
}

/* 针对屏幕宽度在601px到768px之间的设备 */
@media screen and (min-width: 601px) and (max-width: 768px) {
    header {
        width: 100%;
        height: 56px;
        margin: 0 auto;
        padding: 0 40px;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: white;
    }

    header .googleicon {
        width: 40px;
        height: 40px;
        border-radius: 20%;
        margin-right: 10px;
    }

    .information h1 {
        font-size: 37px;
    }

    .container .appicon img {
        width: 180px;
        height: 180px;
        border-radius: 20px;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
            0 1px 3px 1px rgb(60 64 67 / 15%);
    }

    .carousel-image {
        min-width: 20%;
        /* 每次显示3张图片 */
        margin-right: 16px;
        box-sizing: border-box;
        border-width: 0;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
            0 1px 3px 1px rgb(60 64 67 / 15%);
        border-radius: 8px;
        cursor: pointer;
        /* height: 296px; */
        max-width: 50%;
        /* min-width: 92px; */
    }
}

/* 针对屏幕宽度小于600px的设备 */
@media screen and (max-width: 600px) {
    header {
        box-sizing: border-box;
        width: 100%;
        height: 64px;
        margin: 0 auto;
        padding: 0 10px;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: white;
    }

    header .googleicon {
        width: 40px;
        height: 40px;
        border-radius: 20%;
        /* background-color: #a1cfff; */
        margin-right: 10px;
    }

    /* 中间部分 */
    main {
        display: flex;
        /* height: calc(100vh - 64px); */
        /* 高度为视口高度减去64px */
        /* padding-top: 64px; */
        /* 上边距为64px */
        box-sizing: border-box;
        flex-direction: column;
        max-width: 1200px;
        /* 版心最大宽度，可以根据需求调整 */
        /* margin: 0 auto; */
        /* 水平居中 */
        /* padding: 10px 20px; */
        /* 添加内边距，避免内容紧贴页面边缘 */
        /* margin-top: 80px; */
    }

    .appnameinfo,
    .app-rating-download {
        width: 100%;
        margin: 20px 0;
    }
    .containerbg {
    width: 100%;
    height: 250px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.container::after {
    content: "";
    width: 100%;
    height: 250px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: linear-gradient(to top, #202124, rgba(32, 33, 36, 0.1));
}
    /* .secondary-actions .icon_with_text {
        display: flex;
    } */

    .app-actions {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;
    }

    .install-btn {
        width: 100%;
    }

    .sharebox {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #00a173;
        padding: 20px 40px;
        margin: 20px 0;
        font-size: 14px;
    }

    .share_out {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }

    .container .appicon img {
        width: 40px;
        height: 40px;
        border-radius: 7px;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
            0 1px 3px 1px rgb(60 64 67 / 15%);
        display: none;
    }

    .container .appicon_left {
        display: flex;
    }

    .container .appicon_left img {
        display: block;
    }

    .carousel-image {
        margin-right: 16px;
        box-sizing: border-box;
        border-width: 0;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
            0 1px 3px 1px rgb(60 64 67 / 15%);
        border-radius: 8px;
        cursor: pointer;
        height: 204px;
        max-width: 30%;
    }

    footer {
        margin-top: 0px;
        padding: 10px 20px;
    }

    .privacy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 32px;
    }

    .privacy .language-switcher {
        margin-top: 10px;
    }

    .carousel-container {
        padding: 0 20px;
        margin-top: 24px;
        height: 210px;
    }
}

/* 安装提示弹窗 */
.modal {
    display: flex;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
}

.modal-content {
    box-sizing: border-box;
    background-color: #fefefe;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    /* text-align: center;  */
    position: relative;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-content .logo {
    width: 166px;
}

.modal-content .title {
    color: #000;
    font-size: 17px;
    line-height: 24px;
    margin-top: 32px;
    text-align: center;
}

.block {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 100%;
}

.block__icon {
    border-radius: 20%;
    height: 70px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 70px;
    margin-right: 20px;
}

.block__info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.block__info-title {
    line-height: 24px;
    overflow-wrap: anywhere;
    word-break: keep-all;
}

.block__info-title span {
    color: #000;
    font-size: 22px;
    font-weight: 500;
}

.block__info-title img {
    display: inline-block;
    height: 20px;
    margin-left: 3px;
    vertical-align: baseline;
    width: 20px;
}

.block__info-description {
    color: #028760;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0;
}

.block__info-play {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    height: auto;
    min-height: 14px;
    white-space: normal;
    word-break: break-all;
}

.block__info-play img {
    margin-right: 4px;
    width: 10px;
}

.detail {
    align-items: center;
    display: flex;
    font-size: 14px;
}

.detail img {
    width: 70px;
}

.detail__introduction {
    color: #000;
    margin-left: 12px;
}

.detail p {
    line-height: 18px;
    margin: 10px 0;
}

.bold {
    color: #028760;
    font-weight: 500;
}

.btn {
    align-items: center;
    background-color: #028760;
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 35px;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}
</style>