<template>
    <div>
     <!-- 遮罩层 -->
	<div class="zhezhao" id="colse-bady">
		<!-- 顶部applogo列表 -->
		<div class="logolist">
			<ul style="display: flex;align-content: center;flex-wrap: wrap; justify-content: space-between;width: 100%;height: 100%;padding: 36px 24px;">
				<li>
					<img src="../assets/lottery/牛.png" alt="" class="logoimg"/>
				</li>
				<li>
					<img src="../assets/lottery/鼠.png" alt="" class="logoimg"/>
				</li>
				<li>
					<img src="../assets/lottery/马.png" alt="" class="logoimg"/>
				</li>
				<li>
					<img src="../assets/lottery/龙.png" alt="" class="logoimg"/>
				</li>
				<li>
					<img src="../assets/lottery/蛇.png" alt="" class="logoimg"/>
				</li>
				<li>
					<img src="../assets/lottery/虎.png" alt="" class="logoimg"/>
				</li>
				<li>
					<img src="../assets/lottery/羊.png" alt="" class="logoimg"/>
				</li>
				
				<li>
					<img src="../assets/lottery/猴.png" alt="" class="logoimg"/>
				</li>
				<li style="height: 0;width: 70px;"></li>
				<li style="height: 0;width: 70px;"></li>
				<li style="height: 0;width: 70px;"></li>
				<li style="height: 0;width: 70px;"></li>
			</ul>
		</div>
		<!-- 关闭按钮图标 -->
        <img class="closeImg" id="close" src="https://img.pwaload.com/assets/imgs/index2/gb.png">
		
		<!-- 顶部横幅 -->
		<!-- <div class="top">
			<img width="100%" src="https://img.pwaload.com/assets/imgs/index2/top_di.png">
		</div> -->
		<!-- 转盘 -->
		<div class="zhuanpan" id="zhuanpan" @click="rotating">
			<!-- 外框 -->
			<div class="di">
				<img src="../assets/lottery/外框.png" alt="">
			</div>
			<!-- 转盘 -->
			<div class="di2" id="rotatingImage" :style="imageStyle">
				<img src="../assets/lottery/转盘图.png" alt="">
			</div>
			<!-- 中心按钮 -->
			<div class="content wapper">
				<img src="../assets/lottery/中心按钮.png" alt="">
			</div>
		</div>
		<!-- <div style="width: 100%;position: absolute;left: 0;bottom: 0;">
			<div class="buttom">
				<img style="width: 100%;" src="https://img.pwaload.com/assets/imgs/index2/top_di2.png">
			</div>
			<div class="text-box" id="zhuanpandec">
				PWA is a lightweight Android application publishing platform. It can help you easily install your H5 application on an Android phone. Just like ordinary Android applications, it has the same icons and launch methods on the desktop as ordinary Android applications. Using a PWA application is actually very simple. You can complete your PWA application through the following steps.
			</div>
		</div> -->
		
	</div>
      <!-- 抽奖结果 -->
      <div id="zhuanpanresult" v-show="dialogadvice" style="display: flex;flex-direction: column; align-items: center;justify-content: center;position: absolute;top: 50%;z-index: 999; left: 50%;transform: translate(-50%,-50%);background-color: white;padding: 15px;border-radius: 8px;">
          
          <img src="../assets/icon.png" alt="" id="app-icon" style="width: 200px;height: 200px;"/>
          <h1 itemprop="name" class="appname" id="app-title" data-key="softwarename"></h1>
          <p style="color: #5F6368;width: 100%;text-align:center;padding: 20px 0;">Click to install to receive rewards</p>
          <div class="app-actions">
            <button class="install-btn" id="install-btn" style="display: none">Install</button>
            <button class="install-btn" id="play-btn" style="display: none" @click="goplay">play</button>
          </div>
      </div>
      <!-- 提示弹窗 -->
      <div id="modal" class="modal" v-show="showadvice" @click="showadvice=false">
        <div class="modal-content" @click.stop="">
          <img src="../assets/img_reward-DGauptCK.png" alt="logo" loading="lazy" class="logo" />
          <div>
            <p>We are collecting <span style="color: #028760;">objective</span> reviews for this apps</p>
          </div>
          <div class="block">
            <img id="dialoglogo"
              :src="appData.applicationIcon"
              loading="lazy" alt="icon" class="block__icon">
            <div class="block__info">
              <div class="block__info-title">
                <span id="dialogtitle">{{appData.application}}</span>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAA+VBMVEUAAAABf/AAfu8Alf8AfvAAfvAAf/AAgvQAfvAAfu8AfvAAf+8Af/AAgO8Af/AAgPEAgPIAgPAAgvEAgfQAg/QAgO8Ag/8AgPAAf/AAfu8Af/AAf/AAf/AAf/AAfu8Af/AAfu8Af/AAgPAAf/EAgPEAgO8AgPEAgO8AgfIAgPIAhPYAfvAAgfEAgPAAfu////8Jg+/6/P71+f6l0flAnvMZivC/3/us1fmay/hyt/YmkfEQhvDp9P3c7fzM5fvH4/tlsfVKo/M1mPIulfIhjvH+/v7w9/7t9f3j8P3V6fy42/qx1/qPxveHwvd/vvZ7vPZcrPSNxfdTp/Q9lj0RAAAALnRSTlMA/uUF8cudF+HUurGrk3lcTEQzLCAQDGT58uvXzcfCvbSXjH18cmxAOigdZUdGwVZ1LgAAAqNJREFUWMPVmGlX4jAUhknLooKsKuK+o6hvWGVHFMZxd2b+/48Z60AoJ02aNqdn8PnWcPocArn35t7Q92bNdFo113zqNiIwUvxyykBkw5cwiU92s/OL2V1rNenHZxqwINHYbC0WJbAwTB/CFCaETyevm6dhTEj5EEbA2L62Fq63wYh4953DTjyTicPOuWdhAlISno8ggRTi9TAewYUjj4c6DBfC3g73GVw58+K72IIrWxcKolg2nT8+jO8QKEB24ofH+XQ2JnBFIwaBL4gRifLWFWixwgmXoMUSJzyAFgec8ARanHDCDLTIcMJ1Ag3IeohjGRosh3ii0CDqIMxDg7yDMAd1Og3Mkws5UIAid4+UDkawUQg5sQ81fv2gn/QamLHvKFxV/H4V+sU7Zqwyiff0UK/SfzQFqYGRhAqjBzrhN2wk/W543Jv6Kh1AsmkzARUafTpliHkS5lwg70GF7hPzfXAf7tnCuaQWx+Vb5nt1iucSixEDKpBn5qvBCWMSL8Ww8/vt235tbHuuMd8LERT/ouVLb8KRr/erdfbcYr6bMgRspsXVqU7pnPGN+QZdSa0SC9/pxHgPizbz9RuQCMVbZoaHe5YQLB7HELOZFv8pncpU8XOEu9lDB2LCRdmx+aBTesMqnf0AYoyc/GC/Uo5KXVaoSm6h1+J8f8AQhR5LDirGIcQkTLf0xYKD0YaYVbUES5p0xhvEJFVLAHlhvpbsdqhepMrP0xJCZBv2UEbLN5KEIC+jBYiNA97nWuhzkkT91IWcnKfLUrfZgAv5wK9z+hfOoK/E+pf2oNsK/cYn6NZMv3kMvL1lDfiltwb8km/A9UcEwQ8x9McswQ+C9EdV+sO0/z/umxtIXlkLV/aB5CKMTOVD3UUYO0sH44sxul8Q/gJGzKdZ1baZgQAAAABJRU5ErkJggg=="
                  alt="yanzheng" loading="lazy">
  
              </div>
              <!-- <p class="block__info-description" id="dialogdec">{{ appData.introduction }}</p> -->
              <p class="block__info-play">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAoCAMAAACsAtiWAAAAe1BMVEUAAAAuqHoWkG8Ch2AEiGEPjWoCh2ACh2AEimECiGADh2ECiGECh2ADh2EDh2ACiGEDiGIDiGECh2EDh2ACiGACh2ADh2ACh2IDiGIDiGIEiGMGimMHjmMKjGMCh2EFiWMHimQCh2ECiGEDiGACiGACh2EDiGEEh2ACh2ACKEGFAAAAKHRSTlMABQv6jRDs2TzosWnlyLd8VU7ew/XSl3ReSkIpJBfVLyLzzZxvZqOCBWgBPgAAAWpJREFUOMu1lFmSozAQBQsJxL6Zzfvumbz/Cccm2qbVqO2vyQ8URWQ8hQpKYrMujCnW8jt1zkhei5uqg7jc7coYukpmeMsQsquSO+qaQbj0bCFdwOLmverbo04nSQ0Z7JeenbqHbFBjEZQGzo69qzOYMhA5ZtCsxMmqgewoBc2bPqwbCmlR8gZFKyBvgf+gXHSy+aAA/Dl+VND97pMCrR98UuBUqm9KzG6uQHyQkQAjZw4uhVJGNnSSsHIp4Vf2mkJ8yrmi/z5/niu91DQzpZgamFBJoLWylXgpL7wTgUhObSl9IBM1+f05kDxfnKCL5DsFw+PkLc/PknaDPRkb2jHzgi+/cKEf1wN64zYOWm9lpKdxK/krP4i5uYyU7NWPCh3NjUhTTZWP2f40tsY+RsJi+8NYkNgTlWMiaxdDrsR2EnTqTZeJJplPqQ/71de473G3sw4h9KPIf6y1OPHSmJF42nIu3SPuQbbwD+I5Le1jo0NVAAAAAElFTkSuQmCC"
                  loading="lazy" alt="selaf" class="ic_dun">
                Verified by Play Protect
              </p>
            </div>
          </div>
          <div class="detail">
            <img src="../assets/yhj.png" alt="yhj" loading="lazy">
            <div class="detail__introduction">
              <p>
                If you can <span class="bold">download the app</span> and leave an <span class="bold">objective</span>
                review and rating, You will have the chance to receive a <span class="bold">$20</span> Google Play gift
                card.
              </p>
            </div>
          </div>
          <div class="btn" @click.stop="showadvice=false">Install</div>
          <!-- <span class="close">&times;</span>  -->
          <!-- <p>欢迎回来！</p> -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, computed,onUnmounted } from 'vue'
  import { useCounterStore } from '@/stores/index';
  export default {
    // name: 'Home',
    setup() {
      // 使用 ref 创建响应式数据  
      const counter = useCounterStore();
      const appData = ref({
        application: "", // APP名称  
        language: 0,
        applicationIcon: "", // APP图标  
        company: "", // 公司名称  
        viewNumber: "",
        score: "4.5",
        downloadNumber: "", // 下载数量  
        reviews: "", // 评论数  
        updateTime: "",
        detailImages: [], // app详情图片  
        introduction: "", // APP介绍  
        reviewFormList: [
          {
            icon: "", // 用户头像  
            name: "", // 用户名  
            createTime: "",
            review: "" // 用户评论  
          }
        ]
      });
  
      // 请求最新数据  
      const getdatalist = async () => {
        try {
          const response = await fetch(`${counter.downloadUrl}/application/ad/${counter.downloadId}`, { method: 'GET' });
          const data = await response.json();
          console.log(data);
          if (data.code === 200) {
            appData.value = data.data; // 合并新数据到appData中  
          }
        } catch (error) {
          console.log('Error:', error);
          // populateData(); // 如果populateData是用来显示默认数据或错误消息的，那么在这里调用它  
        }
      };
       // 抽奖模块
       let dialogadvice = ref(false)
      // 奖品选项
      const prizes = ref(["first prize", "second prize", "third prize", "fourth prize", "fifth prize", "sixth prize"]);
      const rotationAngles = ref([60, 120, 180, 240, 300, 360]); // 转盘角度对应奖项
      // 当前旋转角度  
      // 是否正在旋转的标志
      let isSpinning = ref(false);
      let rotatingImagetransition = ref('none')
      let rotatingImagetransform = ref('0')
      // 计算属性来组合样式  
      const imageStyle = computed(() => ({
        transition: rotatingImagetransition.value,
        transform: `rotate(${rotatingImagetransform.value}deg)`,
      }));
      const rotating = () => {
  
        if (isSpinning.value) return  // 防止重复点击
        isSpinning = true;
  
        // 随机奖项，假设转5圈后停在某个奖项
        const randomIndex = Math.floor(Math.random() * prizes.value.length);
      //   console.log(randomIndex);
  
        let randomAngle = rotationAngles.value[randomIndex];
        let totalRotation = 360 * 5 + randomAngle; // 旋转3圈后停在目标角度
      //   console.log(randomAngle, totalRotation);
  
        rotatingImagetransition.value = "transform 4s ease-out";
        rotatingImagetransform.value = totalRotation;
      //   console.log(rotatingImagetransition, rotatingImagetransform, imageStyle.value);
  
        // console.log(rotatingImage.style.transform);
        // console.log(randomIndex,randomAngle,totalRotation);
        setTimeout(() => {
          isSpinning = false; // 4秒后恢复可点击状态
          dialogadvice.value = true
          alert(`Congratulations on winning the: ${prizes.value[randomIndex]}!Click to install to receive rewards`);
          
          clearTimeout()
        }, 4000);
      }
  // 安装推荐弹窗
  let showadvice=ref(false)
  const resvedialog=() => {
                    showadvice.value = 'true'; 
           };
   
   // 检查是否在 PWA 环境中
  
   if (window.matchMedia('(display-mode: standalone)').matches) {
             window.location.href = '/'; // 重定向到首页
          //    return false; // 阻止后续下载逻辑的执行
         }else{
             //发起统计预览数量请求 
             fetch(counter.downloadUrl+'/link/readCount/'+counter.downloadId,{method: 'POST'})
             .then(response => response.json())
             .then(data => {})
             .catch(error => console.error('Error:', error));
         }
  
      const goplay=()=>{
        window.location.href='/'
      }
      // 在组件挂载后执行  
      onMounted(() => {
		let ISios = navigator.userAgent || navigator.vendor || window.opera;
            let resa = /iPad|iPhone|iPod/.test(ISios) && !window.MSStream
            let qwe = document.querySelector('#install-btn');

            if (counter.urltype == 2 && resa == false) {
                qwe.style.display = 'block';
                qwe.textContent = 'Download';
                qwe.addEventListener('click', async () => {
                    try {
                        const url = counter.appurl;
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = '';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } catch (error) {
                        console.log('Error:', error);
                    }

                })
            } else if(counter.urltype == 1 && resa == true){
                qwe.style.display = 'block';
                qwe.textContent = 'IOS Clip';
                qwe.addEventListener('click', async () => {
                    window.location.href = '/ios'
                })
            }
          getdatalist()
        
        if (!window.matchMedia('(display-mode: standalone)').matches) {
          // console.log('在pwa内');
          document.addEventListener('visibilitychange', resvedialog);
        }
   
        
      });
      onUnmounted(() => {  
    document.removeEventListener('visibilitychange', resvedialog);  
  });
  
     
      // 返回响应式数据和方法  
      return {
          appData,
        dialogadvice,
        rotating,
        imageStyle,
        goplay,
        rotatingImagetransition,
        rotatingImagetransform,
        // 弹窗推荐安装
        showadvice,
      };
    },
  };
  </script>
  
  <style lang="css" scoped>
  /* 全局样式 */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}
html {
    scroll-behavior: smooth;
}
body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background-color: white;
}
ul,li{
	margin: 0;
	padding: 0;
	list-style: none;
}
header {
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
		0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
/* 顶部logo列表 */
.logolist{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	/* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); */
	/* background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */ 
	  width: 100%; /* 示例宽度，可以根据需要调整 */  
}
	.logoimg{
		width: 60px;height: 60px;margin: 10px 5px;border-radius: 5px;
	}


	
	/* 遮罩层基础样式重置 */
	.zhezhao {
		position: absolute;
		width: 100%;
		bottom: 0;
        left: 0;
		top: 0rem;
		/* background-color: rgba(0, 0, 0, .8); */
		background: url('../assets/lottery/背景.png') no-repeat center center / cover;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		padding: 1rem 0;
	}
	
	.closeImg {
		display: none;
		position: absolute;
		right: 0.5rem;
		width: 2rem;
		top: 0.5rem;
		z-index: 9999;
		background-color: #000;
		border-radius: 50%;
	}
	
	.zhezhao>.top,
	.buttom {
		display: flex;
		align-items: center;
		width: calc(100% - 2rem);
		justify-content: space-around;
		margin-top: 0.2rem;
	}

	.zhuanpan {
		width: 23rem;
		height: 16.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-top: 100px;
	}
	
	.zhuanpan .di,
	.di2,
	.content,
	.di3 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.zhuanpan>.di>img {
		margin: 0 auto;
		height: 100%;
	}
	
	.zhuanpan>.di2>img {
		margin: 0 auto;
		height: 88%;
	}
	
	.zhuanpan>.di3>img {
		height: 50%;
		position: absolute;
		top: 0;
	}
	
	.zhuanpan>.content>img {
		margin: 0 auto;
		height: 5rem;
	}
	
	.zhuanpan>.content>span {
		text-align: center;
		font-size: 2rem;
		color: #fff;
		position: absolute;
		font-weight: 600;
	}
	
	.zhezhao>.buttom {
		position: relative;
		margin-top: 2rem;
	}

	
	.zhezhao .yuanbao {
		position: absolute;
		z-index: 10;
		bottom: -2rem;
		right: 0;
	}
	
	.text-box {
		margin-top: 2rem;
		color: #ABABAB;
		text-align: center;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
	/* 抽奖结果弹窗 */
	.result_box{
		opacity: 0;
		visibility: hidden;
		display: flex;
		flex-direction: column; 
		align-content: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		z-index: 999; 
		left: 50%;
		transform: translate(-50%,-50%);
		background-color: white;
		padding: 15px;
		border-radius: 8px;
		transition: all 0.5s; 
	}
	.appname{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
	}
	.install-btn {
			font-size: 20px;
			background-color: #01875f;
			color: white;
			border: none;
			border-radius: 15px;
			height: 48px;
			/* padding: 0 50px; */
			min-width: 200px;
			/* 设置最小宽度为 200px */
			width: 100%;
		}
      /* 安装提示弹窗 */
.modal {  
    display: flex;   
    position: fixed;   
    z-index: 1;   
    left: 0;  
    top: 0;  
    width: 100%;   
    height: 100%;   
    overflow: auto;    
    background-color: rgba(0,0,0,0.4);   
       align-items: center;
       justify-content: center;
}  
  
.modal-content {  
    box-sizing: border-box;
    background-color: #fefefe;  
    display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
    padding: 20px;  
    border: 1px solid #888;  
    border-radius: 8px;
    width: 80%;   
    max-width: 500px;  
    /* text-align: center;  */
    position: relative;  
}  
  
.close {  
    color: #aaa;  
    float: right;  
    font-size: 28px;  
    font-weight: bold;  
}  
  
.close:hover,  
.close:focus {  
    color: black;  
    text-decoration: none;  
    cursor: pointer;  
}
.modal-content .logo {
    width: 166px;
}

.modal-content .title {
    color: #000;
    font-size: 17px;
    line-height: 24px;
    margin-top: 32px;
    text-align: center;
}
.block {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .23);
    display: flex;
    margin: 15px 0;
    padding: 20px;
    width: 100%;
}
.block__icon {
    border-radius: 20%;
    height: 70px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 70px;
    margin-right: 20px;
}
.block__info {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
}
.block__info-title {
    line-height: 24px;
    overflow-wrap: anywhere;
    word-break: keep-all;
}
.block__info-title span {
    color: #000;
    font-size: 22px;
    font-weight: 500;
}
.block__info-title img {
    display: inline-block;
    height: 20px;
    margin-left: 3px;
    vertical-align: baseline;
    width: 20px;
}

.block__info-description {
    color: #028760;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0;
}
.block__info-play {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    height: auto;
    min-height: 14px;
    white-space: normal;
    word-break: break-all;
}
.block__info-play img {
    margin-right: 4px;
    width: 10px;
}
.detail {
    align-items: center;
    display: flex;
    font-size: 14px;
}
.detail img {
    width: 70px;
}
.detail__introduction {
    color: #000;
    margin-left: 12px;
}
.detail p {
    line-height: 18px;
    margin: 10px 0;
}
.bold {
    color: #028760;
    font-weight: 500;
}
.btn {
    align-items: center;
    background-color: #028760;
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 35px;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}
/* 针对屏幕宽度大于768px的设备 */
@media screen and (min-width: 769px) {
	header {
		box-sizing: border-box;
		width: 100%;
		height: 64px;
		margin: 0 auto;
		padding: 0 40px;
		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		background-color: white;
	}

	header .googleicon {
		width: 50px;
		height: 50px;
		border-radius: 20%;
		margin-right: 10px;
	}
	/* 遮罩层基础样式重置 */
	
.container a {
			padding: 10px 0;
	}
	.information h1 {
		font-size: 37px;
	}

	.container .appicon img {
		width: 200px;
		height: 200px;
		border-radius: 20px;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
	}
	.carousel-image {
		min-width: 20%;
		/* 每次显示3张图片 */
		margin-right: 16px;
		box-sizing: border-box;
		border-width: 0;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
		border-radius: 8px;
		cursor: pointer;
		height: 296px;
		max-width: 30%;
		/* min-width: 92px; */
	}
}



/* 针对屏幕宽度在601px到768px之间的设备 */
@media screen and (min-width: 601px) and (max-width: 768px) {
	header {
		width: 100%;
		height: 56px;
		margin: 0 auto;
		padding: 0 40px;
		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		background-color: white;
	}

	header .googleicon {
		width: 40px;
		height: 40px;
		border-radius: 20%;
		margin-right: 10px;
	}

	.information h1 {
		font-size: 37px;
	}

	.container .appicon img {
		width: 180px;
		height: 180px;
		border-radius: 20px;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
	}

}



/* 针对屏幕宽度小于600px的设备 */
@media screen and (max-width: 600px) {
	header {
		box-sizing: border-box;
		width: 100%;
		height: 64px;
		margin: 0 auto;
		padding: 0 10px;
		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		background-color: white;
	}

	header .googleicon {
		width: 40px;
		height: 40px;
		border-radius: 20%;
		/* background-color: #a1cfff; */
		margin-right: 10px;
	}

	.container .appicon img {
		width: 40px;
		height: 40px;
		border-radius: 7px;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
			0 1px 3px 1px rgb(60 64 67 / 15%);
		display: none;
	}

	.container .appicon_left {
		display: flex;
	}

	.container .appicon_left img {
		display: block;
	}
}
  </style>